import React, { useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Logo from "../assets/img/logogg.jpg";
import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../redux/services/apiPath";
import html2pdf from "html2pdf.js";
import { a } from "react-router-dom";

const PDFGenerator = () => {
  const contentRef = useRef();
  const [token, setToken] = useState(localStorage.getItem("user"));
  const [pdfdata, setData] = useState("");

  const getData = async () => {
    const data = {
      vendorId: localStorage.getItem("vendorId"),
    };
    const res = await axios.post(`${BASE_URL}/getVendorDetail`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (res.data?.status == "success") {
      setData(res?.data?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  // const generatePDF = async () => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const content = contentRef.current;
  //   const canvas = await html2canvas(content);
  //   const imgData = canvas.toDataURL("image/png");

  //   const imgWidth = 210; // A4 width in mm
  //   const pageHeight = 297; // A4 height in mm
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   let heightLeft = imgHeight;
  //   let position = 0;

  //   pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //   heightLeft -= pageHeight;

  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight;
  //     pdf.addPage();
  //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //   }

  //   pdf.save("download.pdf");
  // };

  // const generatePDF = async () => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const content = contentRef.current;

  //   // Reduce the scale to decrease image dimensions and quality to reduce size
  //   const canvas = await html2canvas(content, {
  //     scale: 2, // You can adjust the scale (default is 1)
  //     useCORS: true,
  //   });

  //   const imgData = canvas.toDataURL("image/jpeg", 0.5); // Adjust the quality (0.5 for 50% quality)

  //   const imgWidth = 210; // A4 width in mm
  //   const pageHeight = 297; // A4 height in mm
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   let heightLeft = imgHeight;
  //   let position = 0;

  //   pdf.addImage(
  //     imgData,
  //     "JPEG",
  //     0,
  //     position,
  //     imgWidth,
  //     imgHeight,
  //     undefined,
  //     "FAST"
  //   ); // Use compression

  //   heightLeft -= pageHeight;

  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight;
  //     pdf.addPage();
  //     pdf.addImage(
  //       imgData,
  //       "JPEG",
  //       0,
  //       position,
  //       imgWidth,
  //       imgHeight,
  //       undefined,
  //       "FAST"
  //     ); // Use compression
  //     heightLeft -= pageHeight;
  //   }

  //   pdf.save("download.pdf");
  // };
  const formattedDate = moment().format("YYYY-MM-DD HH:mm:ss");

  const generatePDF = () => {
    const content = contentRef.current;

    const opt = {
      margin: 1,
      filename: "download.pdf",
      image: { type: "jpeg", quality: 0.5 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(opt).from(content).save();
  };

  const labelStyle = {
    fontWeight: "bold",
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2",
  };

  const tableborder = {
    padding: "8px",
    border: "1px solid #ddd",
    marginTop: "10px",
  };
  const valueStyle = {
    border: "1px solid #ddd",
    padding: "8px",
  };
  const comonHeading = {
    fontSize: "20px",
    margin: "20px",
  };
  const greenheadingBig = {
    fontSize: "18px",
    // marginBlock: "20px",
    marginLeft: "20px",
    color: "green",
  };
  const greenheading = {
    fontSize: "14px",
    // marginBlock: "20px",
    marginLeft: "20px",
    color: "green",
  };
  const redHeading = {
    fontSize: "16px",
    marginLeft: "20px",

    // marginBlock: "20px",
    color: "red",
  };

  const leftMargin = {
    marginLeft: "20px",
  };
  const listStyle = {
    listStyle: "none",
    padding: 0,
    margin: 0,
  };

  const listItemStyle = {
    padding: "10px",
    borderBottom: "1px solid #ccc",
    textAlign: "center",
    fontSize: "14px",
  };
  const notes = {
    padding: "10px",

    // borderBottom: "1px solid #ccc",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
  };
  const marginStyle = {
    marginTop: "20px",
    marginBottom: "20px",
  };
  return (
    <div>
      <center>
        <button
          onClick={generatePDF}
          type="button"
          className="downloadbtn"
          // className="steps-btn float-right  m-5 w-25"
        >
          Download PDF
        </button>
      </center>
      <div ref={contentRef} className="pdf-content">
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel ms-panel-fh">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ height: "200px", width: "200px", margin: "20px" }}
                  src={Logo}
                />
              </div>
              <div style={leftMargin}>
                <div>
                  <div>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "green",
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      RESTAURANT PARTNER
                    </p>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "green",
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      ENROLMENT FORM (“FORM”)
                    </p>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "green",
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      FOR TABLE BOOKING AND FOOD ORDERING SERVICES
                    </p>
                  </div>

                  <div>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tbody>
                        <tr>
                          <td style={labelStyle}>Effective Date:</td>
                          <td style={valueStyle}>
                            {moment(
                              moment(pdfdata?.date).format("DD,MMM,y hh:mm a")
                            ).format("DD,MMM,y hh:mm a")}
                          </td>
                        </tr>
                        <tr>
                          <td style={labelStyle}>Restaurant Name:</td>
                          <td style={valueStyle}>{pdfdata?.vendorName}</td>
                        </tr>
                        <tr>
                          <td style={labelStyle}>
                            Restaurant's Business Address:
                          </td>
                          <td style={valueStyle}>{pdfdata?.address}</td>
                        </tr>
                        <tr>
                          <td style={labelStyle}>
                            Legal Entity Name ("Restaurant Partner"):
                          </td>
                          <td style={valueStyle}> {pdfdata?.vendorName}</td>
                        </tr>
                        <tr>
                          <td style={labelStyle}>Communication Address:</td>
                          <td style={valueStyle}>{pdfdata?.address}</td>
                        </tr>
                        <tr>
                          <td style={labelStyle}>Contact Person:</td>
                          <td style={valueStyle}>{pdfdata?.managerName}</td>
                        </tr>
                        <tr>
                          <td style={labelStyle}>Phone:</td>
                          <td style={valueStyle}>{pdfdata?.phone}</td>
                        </tr>
                        <tr>
                          <td style={labelStyle}>Email Id:</td>
                          <td style={valueStyle}>{pdfdata?.email}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div style={tableborder}>
                    <h3>Services</h3>
                    <p>
                      The Restaurant Partner has decided to avail Services from
                      Sortyfy including the following:
                    </p>
                    <ul>
                      <li>
                        <strong>
                          Table Booking Order placement and catalog hosting
                          services:
                        </strong>{" "}
                        Sortyfy provides the Table booking and Order placement
                        mechanism for the Customers to book the table and place
                        Orders with the Restaurant Partners on a real-time basis
                        and helps with listing of the menu and price lists as
                        provided by the Restaurant Partners.
                      </li>
                      <li>
                        <strong>Demand generation and marketing:</strong>{" "}
                        Sortyfy helps bring new Customers to Restaurant Partners
                        every day. This is done through targeted marketing
                        enabling appropriate discovery and creating a seamless
                        Table bookings and food ordering experience.
                      </li>
                      <li>
                        <strong>Other Services:</strong> Sortyfy creates
                        competitive earning opportunities through Takeaway and
                        Services. (Services: As per Term mentioned)
                      </li>
                      <li>
                        <strong>Support for Customers & Restaurants:</strong>{" "}
                        Customers & Restaurant Partners all have unique needs
                        that often need addressing immediately. Sortyfy has a
                        team working 24*7/365 ready to help solve any issues so
                        that the Customers & Restaurants experience is seamless.
                      </li>
                      <li>
                        <strong>Technology infrastructure:</strong> Sortyfy
                        builds and supports products for Customers and
                        Restaurants including payment infrastructure which
                        requires high-quality talent and investments.
                      </li>
                    </ul>
                  </div>
                  <div style={tableborder}>
                    <h3>II. Charges</h3>
                    <p>
                      For the Services above, the Restaurant Partner shall be
                      liable to pay Sortyfy applicable charges as may be
                      communicated to the Restaurant Partner from time to time
                      including as set out under Annexure A and the Terms
                      (defined below). Such charges and other amounts shall be
                      subject to applicable taxes.
                    </p>
                  </div>
                  <div style={tableborder}>
                    <h3>III. Payment Settlement Process</h3>
                    <p>
                      Sortyfy shall transfer the Order Value received to the
                      Restaurant Partner after deduction of agreed Charges and
                      commission on a 24 Hrs Basis. The payment settlement time
                      for Orders serviced will be within 24 Hrs. If the payment
                      settlement day falls on a bank holiday it shall be the
                      following working day.
                    </p>
                  </div>
                  <div style={tableborder}>
                    <h3>IV. Additional Terms</h3>
                    <ul>
                      <li>
                        The Restaurant Partner will be offering the Logistics
                        Services as agreed and agrees not to charge Service
                        charges from any Customers.
                      </li>
                      <li>
                        The Restaurant Partner agrees not to charge the Customer
                        for anything other than food, beverages, and packaging
                        charges on the Platform.
                      </li>
                      <li>
                        The Restaurant Partner will at all times maintain equal
                        or lower prices for all its products offered to the
                        Customers via the Platform as against the prices through
                        its direct channels including dine-in takeaway or
                        Services from its own Restaurant or franchise locations
                        or its other channels like websites/apps etc.
                      </li>
                      <li>
                        For the purpose of clarity, pricing of products shall
                        include pricing of food and beverage items, charges for
                        packaging, etc.
                      </li>
                      <li>
                        In case special portion sizes are created for the
                        Platform, the Restaurant Partner shall maintain
                        proportionate or lower pricing for such portion sizes
                        for the Platform compared to portion sizes on its own
                        channel(s).
                      </li>
                      <li>
                        The Restaurant Partner will desist from directly or
                        indirectly giving or offering any marketing material
                        including flyers, brochures, etc. to Customers with the
                        Orders placed via the Platform containing commercial
                        offers such as discounts to Customers and/or text
                        images, statements, or content which directly or
                        indirectly discourages the Customers from booking tables
                        and placing orders with the Sortyfy platform.
                      </li>
                      <li>
                        This Form forms an integral part of the Terms and
                        Conditions for Table booking and Food Ordering Services
                        available at https://www.Sortyfy.com (“Terms”). This
                        Form (along with its annexures) and the Terms constitute
                        a legally binding contract between the Parties.
                      </li>
                    </ul>
                  </div>

                  <h3 style={comonHeading}>Annexure A</h3>
                  <div>
                    <label style={greenheading}>
                      Restaurant Name (Res ID):{" "}
                    </label>
                    <span style={redHeading}>{pdfdata?.vendorName}</span>
                  </div>
                  <div>
                    <label style={greenheading}>Locality: </label>
                    <span style={redHeading}> {pdfdata?.address}</span>
                  </div>

                  <div style={tableborder}>
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Description
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Charges and other Amounts Payable
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Effective from
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Service Fee
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <ul>
                              <li>0% service fee for first 30 days.</li>
                              <li>
                                Base service fee = 5% of Net Sales per Order
                                (after 30 days)
                              </li>
                            </ul>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              color: "red",
                            }}
                          >
                            {moment(pdfdata?.date).format("DD,MMM,y hh:mm a")}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            For new onboarded restaurants, Service Fee will be
                            effective from the time of going live on the
                            platform.
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Payment Mechanism (Payment Gateway) Fee
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            As per the actual charges gateway charges. Not
                            Exceeding 1.5% of Order Value
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              color: "red",
                            }}
                          >
                            {moment(pdfdata?.date).format("DD,MMM,y hh:mm a")}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Charges for Orders requiring support
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            If Orders requiring Sortyfy Team support:
                            <ul>
                              <li>Free - service fee for first 30 days.</li>
                              <li>
                                INR 10.00 per Order for all Orders requiring
                                support
                              </li>
                            </ul>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              color: "red",
                            }}
                          >
                            {moment(pdfdata?.date).format("DD,MMM,y hh:mm a")}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Exception for new Restaurant for four (4) weeks from
                            the Effective Date. The Restaurant Partner agrees
                            that this amount will be deducted from its ongoing
                            payouts.
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Charges for Restaurant Partner Cancels or Unaccepted
                            Orders
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            IfRestaurant Partner Cancels Orders (After
                            Accepting) or don’t accept within 15 Minutes: INR 50
                            Per Cancelled or unacceptedbookings.
                            <br />
                            If weekly Restaurant Partner Cancels Orders (After
                            Accepting) or don’t accept within 15Minutes, exceed
                            more than 2% of weekly Order volume: INR.250 will be
                            charged to the Restaurant Partner for the relevant
                            week.
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              color: "red",
                            }}
                          ></td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Exception for new Restaurant for two (2) weeks from
                            the Effective Date. The Restaurant Partner agrees
                            that this amount will be deducted from its ongoing
                            payouts.
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Charges for Price Disparity
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Three (3) times the differential amount, for each
                            Order
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              // color: "red",
                            }}
                          >
                            Date on which the difference in price of the
                            product(s) is introduced to the Platform, until the
                            disparity in prices is corrected by the Restaurant
                            Partner.
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Sortyfy may use various means including but not
                            limited to customer complaints and mystery shopping
                            to identify price disparity between a Restaurant’s
                            offline (dine-in) menu prices, and the prices on the
                            Platform. The Restaurant Partner agrees that this
                            amount will be deducted from its ongoing payouts.
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Charges for discouraged practices and offers
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            An amount of up to INR.25000 for each contravention.
                            For repeated contraventions, INR.5000 and/or
                            suspension or termination of Services.
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              color: "red",
                            }}
                          >
                            {moment(pdfdata?.date).format("DD,MMM,y hh:mm a")}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            The Restaurant Partner agrees that this amount will
                            be deducted from its ongoing payouts.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <ul style={listStyle}>
                      <li style={listItemStyle}>
                        <span style={greenheading}>
                          1. Orders requiring support:
                        </span>
                        Orders where support teams extend additional support to
                        mitigate customer escalations for reasons attributable
                        to the Restaurant Partner including but not limited to,
                        delay in accepting or handing over the Order(s), poor
                        quality food, missing or incorrect item, poor quality
                        packaging, etc.
                      </li>
                      <li style={listItemStyle}>
                        <span style={greenheading}>
                          2. Restaurant Partner rejected Orders:
                        </span>
                        Orders which are not accepted (i.e., on account of
                        rejection or inaction resulting in time-out) by the
                        Restaurant Partner or accepted but not fulfilled by the
                        Restaurant Partner.
                      </li>
                      <li style={listItemStyle}>
                        <span style={greenheading}>3. Price Disparity:</span>
                        The Restaurant Partner will at all times maintain equal
                        or lower prices for all its products offered to the
                        Customers via the Platform as against the prices through
                        its direct channels including dine-in, takeaway or
                        Services from its own Restaurant or franchise locations
                        or its other channels like websites/apps etc. For the
                        purpose of clarity, pricing of products shall include
                        pricing of food and beverage items, charges for
                        packaging, etc. In case special portion sizes are
                        created for the Platform, the Restaurant Partner shall
                        maintain proportionate or lower pricing for such portion
                        sizes for the Platform, compared to portion sizes on its
                        own channel(s).
                      </li>
                      <li style={listItemStyle}>
                        <span style={greenheading}>
                          {" "}
                          4. Discouraged practices and offers:
                        </span>
                        The Restaurant Partner will desist from directly or
                        indirectly, sending any marketing material, including
                        flyers, brochures, etc., to Customers with the Orders
                        placed via the Platform, containing commercial offers
                        such as discounts to Customers and/or text, images,
                        statement or content which directly or indirectly
                        discourages the Customers from placing orders with the
                        Sortyfyplatform.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <span style={notes}>
                      Sortyfy may from time to time revise the above-mentioned
                      Charges with prior intimation to the Restaurant Partner in
                      accordance with the Terms.
                    </span>
                  </div>

                  <div style={marginStyle}>
                    <h3 style={comonHeading}>Annexure B</h3>
                    <h3 style={comonHeading}> Bank Details</h3>
                    <div>
                      <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Beneficiary name
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Bank name
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Account number
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              IFSC
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Branch Name
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "red",
                              }}
                            >
                              {pdfdata?.vendorName}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "red",
                              }}
                            >
                              {pdfdata?.bankName}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "red",
                              }}
                            >
                              {pdfdata?.accountNumber}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "red",
                              }}
                            >
                              {pdfdata?.ifsc}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "red",
                              }}
                            >
                              {pdfdata?.branchName}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <p style={notes}>
                        We hereby certify that the details provided above are
                        correct, that the bank account is an account legally
                        opened and maintained by me/our organization, and that
                        I/we shall be liable to the maximum extent possible
                        under applicable law in the event any details provided
                        above are found to be incorrect.
                      </p>
                    </div>

                    <div>
                      <div
                        style={{
                          border: "1px solid #ccc",
                          padding: 20,
                          margin: 20,
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <p
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Signature : {pdfdata?.signature}
                          </span>
                        </p>
                        <p
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          Executed on behalf of the Restaurant Partner by its
                          Authorized Signatory:
                        </p>
                        <p
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Signatory email:
                          </span>{" "}
                          <span> {pdfdata?.email}</span>
                        </p>
                        <p
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Signed at:</span>{" "}
                          <span>
                            {" "}
                            {moment(pdfdata?.date).format("DD,MMM,y hh:mm a")}
                          </span>
                        </p>

                        <p
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", marginTo: "20px" }}
                          >
                            Registered Address:
                          </span>{" "}
                          <span>{pdfdata?.address}</span>
                        </p>
                        <p
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>{pdfdata?.idType}:</span>{" "}
                          <span>{pdfdata?.customerId}</span>
                        </p>
                        <p
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            SORTYFY LIMITED
                          </span>
                        </p>
                      </div>
                    </div>

                    <div>
                      <div>
                        <p style={greenheading}>
                          TERMS AND CONDITIONS OF RESTAURANT PARTNER ENROLMENT
                          FORM FOR FOOD ORDERING and Table Booking ("TERMS"){" "}
                        </p>
                        <p style={greenheading}>
                          These Terms form part of the Restaurant Partner
                          Enrolment Form for Food Ordering and Table Booking
                          ("Form") and constitute a legally binding agreement
                          made between you, whether personally or on behalf of
                          an entity (the "Restaurant Partner"), and Sortyfy
                          Limited and its affiliates (collectively, "Sortyfy"),
                          regarding use of Sortyfy's Platform (as defined below)
                          for the provision of Restaurant Services (as defined
                          below) by the Restaurant Partner to the Customers (as
                          defined below).
                        </p>
                        <ol>
                          <li>
                            <strong>A. "Asset Handover Form"</strong> means the
                            form shared by Sortyfy, which includes details of
                            the assets provided by Sortyfy to the Restaurant
                            Partner and annexed to the Form.
                          </li>
                          <li>
                            <strong>B. "Calendar Month"</strong> means a month
                            as named in the English calendar.
                          </li>
                          <li>
                            <strong>
                              C. "Service fee (formerly known as Commission)"
                            </strong>{" "}
                            means the amount payable by the Restaurant Partner
                            to Sortyfy, as set out in the Form.
                          </li>
                          <li>
                            <strong>D. "Customer"</strong> means users who place
                            Orders through the Platform.
                          </li>
                          <li>
                            <strong>E. "Customer Application"</strong> means the
                            proprietary online website and/or mobile based Order
                            placement application of Sortyfy available on the
                            Platform, which enables the Customers to (a) place
                            an Order with the Restaurant Partner for the purpose
                            of availing Restaurant Services; and (b) track the
                            status of the Order placed by such Customer with the
                            Restaurant Partner; and (c) facilitates a provision
                            of payment by the Customer towards the Restaurant
                            Services availed from the Restaurant Partner.
                          </li>
                          <li>
                            <strong>F. "Customer Data"</strong> means any and
                            all identifiable information about Customer provided
                            by the Customer via the Platform, including, but not
                            limited to, Customer’s name, Services addresses,
                            email addresses, phone numbers, and Customer
                            preferences, to be governed by the{" "}
                            <a
                              href={`https://sortyfy.com/privacy_policy.html `}
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            .
                          </li>
                          <li>
                            <strong> "Electronic Payment Mechanism"</strong>{" "}
                            means the online and mobile based payment mechanisms
                            including the third-party payment gateways, credit
                            card/debit card/net banking transfer and/or
                            e-wallets and/ or Sortyfy credits that are available
                            on the Platform for the purposes of facilitating the
                            payment of the Order Value by the Customer.
                          </li>
                          <li>
                            <strong> "Execution Date"</strong> means the date of
                            execution of Form.
                          </li>
                          <li>
                            <strong> "Form"</strong> means the Restaurant
                            Partner Enrolment Form for Food Ordering and Table
                            Booking executed by the Restaurant Partner.
                          </li>
                          <li>
                            <strong> "Gross Sales"</strong> means the gross
                            amount charged by the Restaurant Partner to any
                            Customer that is attributable to any Order placed
                            through Sortyfy's Platform including all applicable
                            taxes less discounts being offered by the Restaurant
                            on Sortyfy’s Platform (if any).
                          </li>
                          <li>
                            <strong>"Information"</strong> means the information
                            set out and provided along with the Form and
                            includes any information which is supplied by the
                            Restaurant Partner to Sortyfy under these Terms such
                            as Restaurant Partner’s name, establishment name,
                            logo, the Menu items and images for menu items, the
                            price lists underlying the Menu, opening hours of
                            the restaurants operated by the Restaurant Partner,
                            rates at which taxes are charged by the Restaurant
                            Partner to the Customer, Services areas serviced by
                            the restaurants and/or Services terms, specific
                            information the Restaurant Partner is under an
                            obligation to supply to Sortyfy (a) immediately on
                            the Execution Date; or (b) within 1 (one) day from
                            any change in such information.
                          </li>
                          <li>
                            <strong> "Menu"</strong> means any document or
                            virtual page, which lists out the items for sale
                            offered by the Restaurant Partner to the Customer,
                            on the Platform.
                          </li>
                          <li>
                            <strong> "Menu Item Categorisation"</strong> means
                            tagging with respect to food items between
                            Restaurant Service and Supply of Foods and Beverages
                            Items (as defined below) as confirmed by the
                            Restaurant Partner to Sortyfy.
                          </li>
                          <li>
                            <strong> "Merchant Application"</strong> means the
                            Sortyfy developed Order management application
                            pre-loaded in the Tablet and/ or Sortyfy Device
                            which provides Restaurant Partner the ability to (a)
                            receive an Order for the Restaurant Services; (b)
                            allows the Restaurant Partner to accept or reject
                            the Order within stipulated time; (c) provide
                            updates on the Customers Order and its status; and
                            (d) place a request for Logistics Services, if
                            applicable.
                          </li>
                          <li>
                            <strong> "Multiple Outlet Entity"</strong> means the
                            Restaurant, which has more than one outlet under the
                            same ownership providing Restaurant Services.
                          </li>
                          <li>
                            <strong> "Net Order Value"</strong> means Order
                            Value received, less the Service fee and any other
                            additional amount, charges etc. that are due to
                            Sortyfy from the Restaurant Partner under these
                            Terms or the Form.
                          </li>
                          <li>
                            <strong> "Net Sales"</strong> means the Gross Sales
                            less applicable taxes charged by the Restaurant and
                            Services charge and any similar charges levied by
                            the Restaurant (if any);
                          </li>
                          <li>
                            <strong> "One Time Sign–Up Fee"</strong> means a
                            one-time non-refundable amount set out in the Form,
                            payable by the Restaurant Partner when availing the
                            Services for the first time from Sortyfy.
                          </li>
                          <li>
                            <strong> “Optional Services"</strong> means the
                            optional services offered to the Restaurant Partner
                            by Sortyfy from time to time.
                          </li>
                          <li>
                            <strong> "Order"</strong> means the placement of an
                            order by the Customer with the Restaurant Partner
                            for the purchase of any item via the Platform.
                          </li>
                          <li>
                            <strong> "Order Value"</strong> means the amount
                            which is payable by the Customer upon placement of
                            an Order with the Restaurant Partner on the Platform
                            for the Restaurant Service.
                          </li>
                          <li>
                            <strong> "Parties"</strong> means Sortyfy and the
                            Restaurant Partner.
                          </li>
                          <li>
                            <strong> "Payment Mechanism Fee"</strong> means the
                            amount payable by the Restaurant Partner to Sortyfy,
                            being a % of Order Value including taxes less any
                            charge not levied by Restaurant but collected by
                            Sortyfy, as more specifically set out in the Form;
                          </li>
                          <li>
                            <strong> "Platform"</strong> means the Website and
                            Customer Application owned and operated by Sortyfy.
                          </li>
                          <li>
                            <strong> "Pre-Packaged Goods"</strong> means the
                            food and beverages items packaged in such a manner
                            that the contents cannot be changed without
                            tampering it and which is ready for sale to the
                            Customer and as may be defined under the Food Safety
                            and Standards Act, 2006 from time to time.
                          </li>
                          <li>
                            <strong>
                              "Restaurant Partner Compensation Policy"
                            </strong>{" "}
                            means the compensation policy available at
                            https://www.Sortyfy.com and applicable to the
                            Restaurant Partner in the event an Order is
                            cancelled or rejected for reasons not attributable
                            to the Restaurant Partner.
                          </li>
                          <li>
                            <strong> "Restaurant"</strong> means a commercial
                            establishment(s) for which the Restaurant Partner is
                            executing the Form, and from where the Restaurant
                            Services are made available to the Customer,
                            moreover wherein the food and beverage items are
                            prepared and/or Serviceed to the Customer.
                          </li>
                          <li>
                            <strong> "Restaurant Partner"</strong> means the
                            entity/individual being the legal owner of the
                            Restaurant as mentioned in the Form.
                          </li>
                          <li>
                            <strong> "Restaurant Service"</strong> means supply
                            by way of service of food and beverages items
                            attracting tax under Section 9(5) of the Central
                            Goods and Services Tax Act, 2017, listed on the Menu
                            from time to time, sold by the Restaurant, listed
                            and advertised by the Restaurant Partner on the
                            Platform.
                          </li>
                          <li>
                            <strong> "Service Operator"</strong> means the
                            Sortyfy operated centralized system used for
                            receiving Orders from Customers through the Customer
                            Application and transmitting those to the Restaurant
                            Partner.
                          </li>
                          <li>
                            <strong>"Services"</strong> means the services
                            offered by Sortyfy to the Restaurant Partner, on and
                            from the Effective Date, including the following:
                            <ul>
                              <li>
                                Order placement and catalog hosting services:
                                Sortyfy provides the Order placement mechanism
                                for the Customers to place Orders with the
                                Restaurant Partner on a real-time basis and
                                helps with listing of the menu and price lists
                                as provided by the Restaurant Partner.
                              </li>
                              <li>
                                Demand generation and marketing: Sortyfy helps
                                bring new Customers to Restaurant Partner every
                                day. This is done through targeted marketing,
                                enabling appropriate discovery and creating a
                                seamless food ordering experience.
                              </li>
                              <li>
                                Support for Customers, Restaurants Partners:
                                Customers, Restaurant Partner, all have unique
                                needs that often need addressing immediately.
                                Sortyfy has a team working 24*7/365 ready to
                                help solve any issues, so that the Customers,
                                Restaurants Partners experience is seamless.
                              </li>
                              <br />
                              <br />
                              <br />
                              <li>
                                Technology infrastructure: Sortyfy builds and
                                supports products for Customers, Restaurants
                                Partners including payment infrastructure which
                                requires high quality talent and investments.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>"Single Outlet Entity"</strong> means the
                            Restaurant, which has only one outlet providing
                            Restaurant Services.
                          </li>
                          <li>
                            <strong>"Orders requiring support"</strong> means
                            order where Sortyfy support teams extend additional
                            support to mitigate customer escalations including
                            but not limited to, delay in accepting or handing
                            over the Order(s), poor quality food, missing or
                            incorrect item, poor quality of services or
                            packaging, etc.
                          </li>
                          <li>
                            <strong>
                              "Supply of Food and Beverages Items"
                            </strong>{" "}
                            means supply of any food and beverages items, made
                            by the Restaurant through the Platform, other than
                            Restaurant Service attracting tax under Section 9(5)
                            of the Central Goods and Services Tax Act, 2017.
                          </li>
                          <li>
                            <strong>"Tablet"</strong> means an electronic Order
                            placement Tablet device preloaded with the Merchant
                            Application.
                          </li>
                          <li>
                            <strong>"Website"</strong> means{" "}
                            <a
                              href="https://www.sortyfy.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              www.Sortyfy.com
                            </a>{" "}
                            (including the webpages contained or hyperlinked
                            therein and owned or controlled by Sortyfy), and
                            such other media or media channels, devices, mobile
                            applications, software, or technologies as Sortyfy
                            may choose from time to time.
                          </li>
                          <li>
                            <strong>"Sortyfy Device"</strong> means a smart
                            phone loaded with the Merchant Application.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <div>
                        <h2 style={greenheadingBig}>
                          {" "}
                          2. Sortyfy’s Obligations:
                        </h2>

                        <h3 style={greenheading}>A. Sortyfy will:</h3>
                        <ol>
                          <li>
                            (a) list Restaurant Partner’s menu and the price
                            list on the Platform; and
                          </li>
                          <li>
                            (b) transfer to the Restaurant Partner the amounts
                            received from the Customers in accordance with
                            agreed Terms set out herein.
                          </li>
                        </ol>

                        <h3 style={greenheading}>B. Sortyfy will:</h3>
                        <ol>
                          <li>
                            display on the Platform, on a best effort basis, all
                            necessary information provided by the Restaurant
                            Partner. However, Sortyfy is not under any
                            obligation to display any information until the
                            Restaurant Partner provides all required information
                            and such information is in compliance with Sortyfy’s
                            policies and guidelines.
                          </li>
                        </ol>

                        <h3 style={greenheading}>C. Sortyfy will:</h3>
                        <ol>
                          <li>
                            retain the right to change the rate of taxes on the
                            Menu item list shared by the Restaurant Partner for
                            listing on the Platform.
                          </li>
                        </ol>
                        <h3 style={greenheading}>D. Sortyfy will:</h3>
                        <ol>
                          <li>
                            will transmit the Orders placed by the Customer with
                            the Restaurant Partner as per below:
                          </li>

                          <li>
                            Restaurant Partner having Tablet or Sortyfy Device
                            with pre-loaded Merchant Application or receiving
                            Orders through a third-party point of sale system or
                            Sortyfy API or a Sortyfy provided web dashboard,
                            Order transmission on a real time basis;
                            orRestaurant Partner Device having the Merchant
                            Application.
                          </li>

                          <li>
                            Restaurant Partner using their own device, without
                            Merchant Application to receive Orders: Order
                            transmission through SMS and/ or phone call on an
                            immediate basis through the Service Operator. In the
                            event, the Restaurant Partner fails to answer the
                            phone calls over a period of two (2) minutes,
                            Sortyfy reserves the right to temporarily restrict/
                            deactivate Restaurant Partner's Food Ordering and
                            Table Booking to avoid any Customer complaints.
                            Restaurant Partner's access shall be reinstated upon
                            specific request made to Sortyfy customer support
                            center. Sortyfy reserves the right to cancel any
                            Order where Restaurant Partner is unable to
                            communicate its response within two (02) minutes on
                            (a) acceptance/ rejection of the Customer Order and/
                            or (b) Order Services timelines; or
                          </li>

                          <li>
                            Where transmission of Order is to the Restaurant
                            Partner owned device without Merchant Application,
                            Sortyfy’s Service Operator shall communicate
                            Customer Order to the Restaurant Partner (through
                            SMS and/or phone call) and communicate Restaurant
                            Partner response with respect to (i) acceptance /
                            rejection of Order; (ii) Services time; (iii)
                            no-response from Restaurant Partner or any such
                            information as may be made available by Sortyfy from
                            time to time; to the Customer through Customer
                            Application. The Restaurant Partner agrees that all
                            communication undertaken by Sortyfy with the
                            Restaurant Partner via phone call may be recorded.
                          </li>
                          <li>
                            Sortyfy will redress the Customers and the
                            Restaurant Partner’s complaints in respect of the
                            functioning of the Platform and/or the Tablet or
                            Sortyfy Device (as the case may be).
                          </li>

                          <li>
                            For the avoidance of doubt it is hereby expressly
                            clarified that Sortyfy is only responsible for
                            providing (i) a Platform to the Restaurant Partner
                            to list, offer and sell the Restaurant Services to
                            the Customers and/or and that Sortyfy will not be
                            responsible or liable for (i) the quality of the
                            Restaurant Services listed and advertised on the
                            Platform; and/or (ii) the processing of the Orders
                            which have been placed by the Customers with the
                            Restaurant Partner on the Platform; and/or (iii) any
                            delay in preparation of the Order by Restaurant
                            Partner.
                          </li>

                          <li>
                            Sortyfy may suspend the Restaurant Partner’s account
                            if the Restaurant Partner is found non-compliant
                            with the Food Safety and Standards Act, 2006 and the
                            rules, regulations, licenses, standards and
                            guidelines issued thereunder from time to time.
                          </li>

                          <li>
                            Restaurant Partner shall ensure that all mandatory
                            information pertaining to taxes, levies, and charges
                            applicable on the Order(s) are clearly visible to
                            the Customers on their invoice issued for any supply
                            other than Restaurant Service, as per the applicable
                            laws. For the purpose of clarity, in the case of
                            Restaurant Service(s), Sortyfy will generate the tax
                            invoice on the Restaurant Partner’s behalf in
                            accordance with the applicable GST laws and deposit
                            the tax to the appropriate tax authorities.
                          </li>

                          <li>
                            Restaurant Partner will ensure that the Information
                            provided to Sortyfy is current and accurate,
                            including but not limited to the Restaurant Partner
                            name, address, contact telephone number, email,
                            manager/contact person details, Services times,
                            opening hours, Menu(s), price lists, taxes, Menu
                            Item Categorisation, service addresses, and other
                            relevant information.
                          </li>

                          <li>
                            Restaurant Partner shall confirm to Sortyfy its Menu
                            Item Categorisation between Restaurant Service and
                            Supply of Foods and Beverages Items. Menu Item
                            Categorisation as confirmed by the Restaurant
                            Partner shall be consumed by Sortyfy for the purpose
                            of undertaking necessary compliance with the
                            applicable laws. In the event of a dispute in Menu
                            Item Categorisation, the Restaurant Partner
                            undertakes to make good any losses incurred by
                            Sortyfy on account for any mis-declaration or
                            mis-representation of facts.
                          </li>

                          <li>
                            Restaurant Partner shall ensure that it is the sole
                            author/owner of, or otherwise controls all the
                            content / material, including but not limited to the
                            Restaurant Partner’s name, establishment name, logo,
                            menu items, images of the food and beverages/ menu
                            items, etc. transmitted or submitted by the
                            Restaurant Partner to Sortyfy either through the
                            Platform or otherwise (“Content”), or has been
                            granted the right to use such Content from the
                            rights holder of such Content and that it does not
                            violate or infringe the rights of any third party or
                            applicable laws;
                          </li>
                          <li>
                            Restaurant Partner shall process and execute the
                            Order(s) promptly.
                          </li>
                          <li>
                            The Restaurant Partner shall be obligated to turn
                            off “Accepting Services” feature on its food
                            ordering mechanism whenever the Restaurant Partner
                            is unable to provide Restaurant Services to the
                            Customer.
                          </li>

                          <li>
                            Restaurant Partner acknowledges and agrees that in
                            the event the Customer’s experience with the
                            Restaurant Partner and the Restaurant Services is
                            hampered on account of acts and omissions
                            attributable to the Restaurant Partner, including
                            but not limited to, frequent rejection of Order(s),
                            Sortyfy reserves the right to take appropriate
                            action in accordance with its policies, which may be
                            amended from time to time.
                          </li>
                          <li>
                            Restaurant Partner shall inform Sortyfy about any
                            change or modification made to the Order by the
                            Customer, directly with the Restaurant Partner.
                          </li>
                          <li>
                            Restaurant Partner hereby agrees that if it accepts
                            the Order cancellation request raised by a Customer
                            via the Platform for Restaurant Partner, it shall
                            not be eligible to receive any Order Value or amount
                            for such cancelled Order.
                          </li>

                          <li>
                            Restaurant Partner shall ensure that when a Customer
                            pays online for an Order and the Restaurant Partner
                            is undertaking logistics, the Restaurant Partner or
                            its representative collects a signed receipt from
                            the Customer upon Services of the Order. In the
                            event of a dispute in respect of whether the
                            Restaurant Partner is entitled to a payment by
                            Sortyfy in accordance with these Terms, Sortyfy will
                            not be required to make any such payment unless the
                            Restaurant Partner provides Sortyfy with a copy of
                            the signed receipt.
                          </li>
                          <li>
                            Restaurant Partner shall retain proof of Services
                            for a period of 180 days from the date of Services.
                          </li>
                          <li>
                            In the event the Restaurant Partner undertakes
                            Services of any Order, it shall ensure only trained
                            personnel undertake such Services and the safety of
                            the food and beverage is not compromised at any time
                            until the Order is received by the Customer. The
                            Restaurant Partner agrees to indemnify Sortyfy for
                            any claims that may arise on account Services of
                            Orders undertaken by the Restaurant Partner.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>
                        The Restaurant Partner warrants that the food and
                        beverages provided to Customers are:
                      </p>
                      <div>
                        <ol>
                          <li>
                            of high quality and fit for human consumption;
                          </li>

                          <li>
                            compliant with Food Safety and Standards Act, 2006
                            and the rules, regulations, licenses, standards and
                            guidelines issued thereunder;
                          </li>

                          <li>
                            compliant with all other relevant Indian
                            legislation, including all applicable food industry
                            regulations.
                          </li>

                          <li>
                            Where the Restaurant Partner avails any Optional
                            Services, the Restaurant Partner will additionally
                            be liable to abide with the terms and conditions of
                            such Optional Services or any other terms and
                            conditions pertaining to the Optional Services,
                            communicated by Sortyfy from time to time.
                          </li>

                          <li>
                            Contact a Customer if an Order cannot be processed
                            as requested or to clarify the details of an Order,
                            if required, post confirmation of the Order.
                          </li>

                          <li>
                            Contact a Customer if an Order cannot be processed
                            as requested or to clarify the details of an Order,
                            if required, post confirmation of the Order.
                          </li>
                          <li>
                            To promptly redress the Customer complaints which
                            have been referred by Sortyfy to the Restaurant
                            Partner in respect of the (a) quality, quantity
                            and/or taste of the food and beverage; (b) Services
                            of the Orders (where such Services has been
                            undertaken by the Restaurant Partner and the
                            Services issues are attributable directly to the
                            Restaurant Partner); and (c) failure on part of the
                            Restaurant Partner to comply with the special
                            requests / instructions of the Customers where such
                            requests and/or instructions have been clearly
                            communicated by the Customer at the time of the
                            placement of Order.
                          </li>
                          <li>
                            The Restaurant Partner shall remove any/all menu
                            items unavailable with the Restaurant Partner.
                          </li>

                          <li>
                            The Restaurant Partner acknowledges and agrees that
                            it will be solely responsible for Serviceing the
                            Order placed by the Customers (where it is not
                            availing Logistics Services) with the Restaurant
                            Partner on the Platform. Unless agreed to by the
                            Restaurant Partner in the Form, it is clarified that
                            Sortyfy will not provide any Logistics Services.
                          </li>
                          <li>
                            Restaurant Partner shall (a) undertake adequate
                            police verification and background checks for its
                            Services personnel and maintain records of the same;
                            (b) ensure that the Services personnel are not
                            intoxicated; (c) ensure that Services personnel
                            don’t misbehave with the Customer; (d) make timely
                            payment of all applicable statutory dues; (e)
                            maintain adequate insurances; and (f) provide proper
                            training to its Services personnel to ensure
                            Services personnel Service the Order efficiently
                            without any spillage or delay.
                          </li>

                          <li>
                            Restaurant Partner shall ensure, while making
                            Services, it does not commingle the Order along with
                            other orders it receives directly from customers or
                            from third parties not using the Platform.
                            Restaurant Partner shall pack the Order either in
                            Sortyfy provided packing material or other packaging
                            material. However, under no circumstances shall the
                            Restaurant Partner use third party branding in the
                            packaging material used for the Order.
                          </li>

                          <li>
                            Restaurant Partner shall ensure, while making
                            Services, it does not commingle the Order along with
                            other orders it receives directly from customers or
                            from third parties not using the Platform.
                            Restaurant Partner shall pack the Order either in
                            Sortyfy provided packing material or other packaging
                            material. However, under no circumstances shall the
                            Restaurant Partner use third party branding in the
                            packaging material used for the Order.
                          </li>
                          <li>
                            1Restaurant Partner acknowledges and agrees that
                            Restaurant Partner shall be required to furnish a
                            copy of its PAN Card, TAN, GSTIN, FSSAI license and
                            registration certificates and other details required
                            as per law or for provision of Services, as maybe
                            requisitioned by Sortyfy from time to time failing
                            which, Sortyfy reserves the right to delist the
                            Restaurant Partner from the Platform and / or
                            curtail it’s access from the Platform.
                          </li>
                          <li>
                            Take Away :The Restaurant Partner shall comply with
                            the applicable law on the ban of single-use plastic
                            and shall ensure that neither single-use plastic is
                            used in packing any Order nor is any cutlery made
                            from single-use plastic provided with the Order.
                            Restaurant Partner agrees and acknowledges that
                            Sortyfy shall reserve a right to cancel all Orders
                            that are packed by the Restaurant Partner using
                            single-use plastic and any payment made to the
                            Services Partner assigned to such orders or
                            refund/compensation made by Sortyfy to the Customers
                            whose Orders are cancelled shall be recovered from
                            Restaurant Partner in accordance with Sortyfy’s
                            cancellation policy. Further, any loss suffered by
                            Sortyfy or the Services Partner due to use of
                            single-use plastic by Restaurant Partner, whether on
                            account of any fine imposed by the authorities on
                            Sortyfy or its Services Partner or otherwise, shall
                            be recovered from the Restaurant Partner by making
                            deductions in accordance to the payment settlement
                            process agreed under these Terms.
                          </li>
                          <li>
                            Restaurant Partner acknowledges and agrees that it
                            shall not make any independent calls to the Customer
                            and asks the Customer to make payments of amounts
                            greater than what was agreed by the Customer at the
                            time of placing the Order through the Platform.
                          </li>
                          <li>
                            Restaurant Partner except required for the
                            fulfillment of Order shall not use the Customer data
                            for sending any unsolicited marketing message,
                            announcements
                          </li>

                          <li>
                            Restaurant Partner shall not engage in any
                            fraudulent activity or misuse any benefits extended
                            by Sortyfy to Customers and shall be liable to
                            Sortyfy in the event Sortyfy becomes aware of any
                            fraudulent activity undertaken by the Restaurant
                            Partner.
                          </li>

                          <li>
                            Restaurant Partner shall not charge any Services
                            and/or Payment Mechanism Fee charges from Customers
                            on Orders where Restaurant Partner is not
                            undertaking Services itself.
                          </li>
                          <li>
                            Where Restaurant Partner avail Logistics Services,
                            Restaurant Partner shall be responsible to delivery
                            of the Order to the Customer.
                          </li>
                          <li>
                            Restaurant Partner shall ensure to update the
                            Merchant Application as and when the latest version
                            of the application is available.
                          </li>
                          <li>
                            Restaurant Partner shall ensure to protect and
                            maintain the Customer experience on the Platform by
                            accurately displaying the details of the Restaurant
                            including but not limited to item names & images in
                            the Menu
                          </li>
                          <li>
                            The Restaurant Partner shall not charge the Customer
                            for anything other than food, beverages, and
                            packaging charges on the Platform. The Restaurant
                            Partner shall ensure that all menu item(s) available
                            for Services to the Customers through its Services
                            channels from its own Restaurant or franchise
                            locations or its other channels like websites/apps
                            etc, shall also be made available for Services to
                            the Customers through the Platform.
                          </li>
                          <li>
                            The Restaurant Partner shall not charge the Customer
                            for anything other than food, beverages, and
                            packaging charges on the Platform. The Restaurant
                            Partner shall ensure that all menu item(s) available
                            for Services to the Customers through its Services
                            channels from its own Restaurant or franchise
                            locations or its other channels like websites/apps
                            etc, shall also be made available for Services to
                            the Customers through the Platform.
                          </li>
                          <li>
                            Restaurant Partner understands and agrees that
                            having similar item names and images in the Menu of
                            two or more Restaurant listings operating from the
                            same location may cause confusion for the Customers
                            and hamper their experience on the Platform.
                          </li>

                          <li>
                            Restaurant Partner understands and agrees that
                            having similar item names and images in the Menu of
                            two or more Restaurant listings operating from the
                            same location may cause confusion for the Customers
                            and hamper their experience on the Platform.
                          </li>

                          <li>
                            It is further clarified that, in the event, the
                            Restaurant listings are found to be in violation of
                            this terms, then Sortyfy shall notify the Restaurant
                            Partner by giving a 30 (thirty) day written notice
                            to make the necessary changes and correct the
                            Restaurant listings. However, in case the Restaurant
                            Partner fails to correct the Restaurant listings,
                            then Sortyfy reserves the right to take appropriate
                            action, which may include removing all the duplicate
                            listings from the Platform, in accordance with its
                            policies and these Terms.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>
                        Restaurant Partner with Tablets and Sortyfy Device (With
                        Embedded Merchant Application):
                      </p>
                      <div>
                        <ol>
                          <li>
                            Shall ensure it has a SortyfyApplication / Web
                            Browser Device at all times in order to receive and
                            confirm Customer Orders and provide estimates of
                            Services times or reject the Order through the
                            Merchant Application;
                          </li>
                          <li>
                            may decline Orders through the SortyfySoftware
                            provided however, Restaurant Partner shall not use
                            alternate means (phone/ emails) to connect with the
                            Customer in parallel to take the same Orders and
                            thereby circumventing the process.
                          </li>
                          <li>
                            Execute the Orders promptly and indicate the
                            estimated time of Services to process the Order
                            Services including updating the Order status (e.g.
                            out for Services etc.) through the Merchant
                            Application.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>
                        Restaurant Partner with Restaurant Partner Device :
                      </p>
                      <div>
                        <ol>
                          <li>
                            Ensuring it has a functional Restaurant Partner
                            Device at all times in Order to A) Receive / Reject
                            Orders, <br />
                            B) provide estimates of Services time, <br /> C)
                            communicate with Service Operators through phone
                            call and/or SMS.
                          </li>
                          <li>
                            Restaurant Partner may decline Customer Orders
                            placed through the Service Operator however,
                            Restaurant Partner shall not use alternate means
                            (phone/ emails) to connect with the Customer in
                            parallel to take the same Orders and thereby
                            circumventing the process.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>Promotions</p>
                      <p>
                        Where a Restaurant Partner has agreed to extend
                        Promotions (as defined below) to Customers on Order
                        placed at the Restaurant via the Platform the Restaurant
                        Partner agrees to the following terms and conditions
                        (“Promotion Terms”):
                      </p>
                      <div>
                        <ol>
                          <li>
                            These Promotion Terms apply to a Restaurant
                            Partner’s engagement in Promotions pursuant to which
                            the Restaurant Partner shall extend discounts or
                            offers, as the case may be, to Customers on Order(s)
                            placed with the Restaurant Partner via the Platform.
                          </li>
                          <li>
                            These Promotion Terms do not alter in any way, the
                            terms or conditions of any other agreement the
                            Restaurant Partner may have with Sortyfy.
                          </li>
                          <li>
                            Unless the context otherwise requires, the
                            capitalized terms used herein and not otherwise
                            defined shall have the meaning assigned to them in
                            the Terms.
                          </li>

                          <li>
                            By accepting to sign up for Promotions, the
                            Restaurant Partner hereby agrees to the below:
                            <br />
                            <br />
                            A. The Restaurant Partner hereby acknowledges and
                            agrees that it shall determine and make available
                            Promotions to Customers at its sole discretion.
                            <br />
                            <br />
                            B. For the purpose of these Promotion Terms,
                            “Promotions” shall mean the discounts or offers
                            determined and made available for Customers on
                            Orders placed with the Restaurant Partners via the
                            Sortyfy Platform. The Promotions are only valid on
                            that particular event, food ordering and Services.
                            <br />
                            <br />
                            C. The Restaurant Partner hereby agrees that the
                            cost of discount under the Promotions shall be
                            completely funded by the Restaurant Partner.
                            <br />
                            <br />
                            D. The Restaurant Partner at its sole discretion
                            shall determine the details and validity period of
                            <br />
                            <br />
                            the Promotions. <br />
                            E. The Restaurant Partner acknowledges and agrees
                            that the Promotions once determined and made
                            available via the Sortyfy Platform cannot be
                            modified during the validity period of such
                            Promotions. However, the Restaurant Partner may
                            replace a Promotion with a new (modified) Promotion,
                            after giving due notice to Sortyfy and after getting
                            agreed with Sortyfy Team.
                            <br />
                            <br />
                            F. The Promotions during the validity period, may be
                            not be suspended or revoked any time at the
                            Restaurant Partner’s sole discretion.
                            <br />
                            <br />
                            G. The Restaurant Partner can have any number of
                            Promotions running at a given time.
                            <br />
                            <br />
                            H. Unless otherwise specified and agreed beforehand,
                            the Promotions can be combined with any other offers
                            extended by the Restaurant Partner or Sortyfy or any
                            other third party.
                          </li>

                          <li>
                            The Restaurant Partner hereby agrees to indemnify
                            and hold Sortyfy and its directors, officers,
                            agents, representatives and employees, harmless from
                            and against any and all claims, suits, liabilities,
                            judgments, losses and damages arising out of or in
                            connection with (a) any claim or suit or demand on
                            account of Restaurant Partner failing to honor any
                            Promotions; and/or (b) breach of any applicable law.
                          </li>

                          <li>
                            You hereby agree and acknowledge that Sortyfy has no
                            role or responsibility towards the Promotion(s) and
                            will not be liable to the Restaurant Partner or the
                            Customer for any claim relating to the Promotion(s).
                          </li>

                          <li>
                            The Promotion(s) are subject to Sortyfy’s approval.
                            Sortyfy reserves the right to reject or remove the
                            Promotion(s) at any time, for reasons communicated
                            to the Restaurant Partner.
                          </li>

                          <li>
                            Sortyfy will determine, in its sole discretion, the
                            placement, and positioning of the Promotion(s) on
                            the Sortyfy Platform.
                          </li>
                          <li>
                            Sortyfy does not guarantee the reach or performance
                            of the Promotion(s).
                          </li>
                          <li>
                            The Restaurant Partner is solely liable for
                            compliance with all applicable laws relating to the
                            Promotions.
                          </li>
                          <li>
                            The arrangement between the parties shall commence
                            from the date of acceptance of these Promotion Terms
                            by the Restaurant Partner and shall be valid and
                            binding unless terminated in accordance with these
                            Promotion Terms.
                          </li>
                          <li>
                            The Restaurant Partner may not at any time opt out
                            of Promotions without any prior notice to Sortyfy.
                          </li>
                          <li>
                            Sortyfy may terminate the arrangement at any time
                            with a one (1) day prior written notice of
                            termination to the Restaurant Partner.
                          </li>

                          <li>
                            Notwithstanding anything to the contrary contained
                            herein, Sortyfy may forthwith suspend and/or
                            terminate the Services if the Restaurant Partner is
                            in breach of these Promotion Terms and such breach
                            has not been rectified within five (5) days of
                            notice of breach.
                          </li>

                          <li>
                            Sortyfy reserves the right to modify the Promotion
                            Terms after prior notice and without liability to
                            the Restaurant Partner. Any such changes will be
                            effective immediately upon the changes being
                            reflected on the Terms.
                          </li>
                          <li>
                            Sortyfy reserves the right to discontinue the
                            Promotions at its own discretion at any time,
                            without any notice or liability to the Restaurant
                            Partner if the same is necessitated due to legal or
                            regulatory requirements. In case of occurrence of
                            such discontinuance, the Restaurant Partner will be
                            provided with adequate reasons for discontinuation
                            of Promotions in due course.
                          </li>

                          <li>
                            Termination of these Promotion Terms shall have no
                            effect on these Terms.
                          </li>
                          <li>
                            All the rights, duties, liabilities and obligations
                            of the respective parties under the Terms shall form
                            an integral part of these Promotion Terms and shall
                            remain unaltered by these Promotion Terms.
                          </li>

                          <li>
                            Except as provided herein and varied/modified, the
                            Terms shall continue to have full force and effect.
                          </li>
                          <li>
                            For any help or queries with regard to Promotions,
                            you may reach out via the help center on the
                            Merchant Application.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>License</p>

                      <div>
                        <ol>
                          <li>
                            Restaurant Partner grants to Sortyfy an
                            unrestricted, non-exclusive, royalty-free licence in
                            respect of all Content (defined hereinabove) and
                            Information provided to Sortyfy by the Restaurant
                            Partner for the purposes of inclusion on the
                            Platform and as may be otherwise required under the
                            Form. This includes, but is not limited to, <br />
                            <br /> (a) use of the Restaurant Partner’s name in
                            the context of Google adwords to support advertising
                            and promotional campaigns to promote food ordering
                            and Services on internet which may be undertaken by
                            Sortyfy
                            <br />
                            <br /> (b) preparation of derivative works of, or
                            incorporate into other works, all or any portion of
                            the marketing materials which will be made by
                            Sortyfy for the purposes of its business.
                          </li>
                          <li>
                            Any Content, information or material that the
                            Restaurant Partner transmits or submits to Sortyfy
                            either through the Platform or otherwise shall be
                            considered and may be treated by Sortyfy as
                            non-confidential, subject to Sortyfy’s obligations
                            under relevant data protection legislation.
                          </li>
                          <li>
                            The Restaurant Partner also grants to Sortyfy a
                            royalty-free, perpetual, irrevocable, non-exclusive
                            license to use, copy, modify, adapt, translate,
                            publish and distribute world-wide any Content,
                            information or material for the purposes of
                            providing Services under these Terms or to or for
                            the purposes of advertising and promotion of the
                            Platform. The Restaurant Partner agrees that all
                            Content , information or material provided to
                            Sortyfy that is published on the Platform, may be
                            relied upon and viewed by Customers to enable them
                            to make informed decisions at the pre-purchase
                            stage.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>
                        Restaurant Partner Menu and Price List
                      </p>

                      <div>
                        <ol>
                          <li>
                            Sortyfy will display on the Platform the menu and
                            price list for all of its Restaurant Partner. The
                            Restaurant Partner agrees that Sortyfy reserves the
                            right to modify and delete certain items from the
                            Restaurant Partner’s menu list at its sole
                            discretion, listed on the Platform, to ensure
                            compliance with Food Safety and Standards Act, 2006,
                            requirements or applicable law in the relevant State
                            or Territory and all other applicable legislation,
                            regulations or regulatory standards. Sortyfy shall
                            endeavour to update price lists within 48 hours of
                            being notified of changes (in writing) by a
                            Restaurant Partner. Where the Restaurant Partner has
                            a unilateral right to access Restaurant Partner
                            admin panel or dashboard (subject to Sortyfy’s
                            written consent in this regard) to edit and update
                            the Information which is displayed on the Platform
                            by Sortyfy, the Restaurant Partner should ensure
                            that it (i) keeps such information true, accurate
                            and updated at all times; and (ii) complies with
                            Sortyfy’s internal terms and conditions of use in
                            this regard and (iii) intimates to Sortyfy about
                            such change.
                          </li>
                          <li>
                            Restaurant Partner shall provide Sortyfy with a
                            separate list of all Pre-Packaged Goods which form
                            part of the Restaurant Partner’s menu list, to be
                            listed on the Platform, in the format acceptable to
                            Sortyfy.
                          </li>
                          <li>
                            The Restaurant Partner shall ensure that the
                            Pre-Packaged Goods listed on the Platform shall have
                            shelf life of 30 percent or forty-five (45) days
                            before expiry at the time of Services to the
                            Customers.
                          </li>
                          <li>
                            The Restaurant Partner will, at all times, maintain
                            equal or lower prices for all its products offered
                            to the Customers via the Platform as against that
                            made available for sale through its direct channels
                            including dine-in, takeaway or Services from its own
                            Restaurant or franchise locations or its other
                            channels like websites/apps etc. For the purpose of
                            clarity, pricing of products shall include pricing
                            of food and beverage items, charges for packaging,
                            etc.
                          </li>
                          <li>
                            If the Restaurant Partner creates special portion
                            sizes for the Platform, compared to portion sizes on
                            its own channel(s) (i.e., dine-in, Services or
                            takeaway), pricing should be proportionate or lower
                            for the menu on the Platform.
                          </li>
                          <li>
                            In the event the Restaurant Partner fails to
                            maintain prices as per the aforementioned paras
                            under clauses 4. and 5, Sortyfy reserves the right
                            to take appropriate action in accordance with its
                            policies, which may be amended from time to time.
                          </li>

                          <li>
                            The Restaurant Partner shall not charge an amount
                            over and above the maximum retail price ("MRP") for
                            all such food and beverage items which have MRP
                            mentioned on them.
                          </li>
                          <li>
                            The Restaurant Partner acknowledges and agrees that
                            Sortyfy will use its best endeavours to ensure that
                            the Platform is not misused by the Customers for the
                            placement of erroneous/fraudulent Orders. In the
                            event of an erroneous/fraudulent Order, the
                            Restaurant Partner undertakes to report such an
                            erroneous/fraudulent Order to Sortyfy through the
                            SortyfySoftware or call Sortyfy for Sortyfy’s action
                            and investigation. In this regard, Sortyfy provides
                            a built-in feature(s) in the Merchant Application
                            and web dashboard access that will allow the
                            Restaurant Partner to report such
                            erroneous/fraudulent Orders.
                          </li>
                          <li>
                            Sortyfy may perform a variety of marketing
                            activities to promote the Restaurant Partner and the
                            Restaurant Partner's menu; provided, however, that
                            all such marketing activities will be determined in
                            Sortyfy’s sole and absolute discretion and the
                            Platform may be modified or updated, without notice
                            and from time to time, to reflect any such changes.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>
                        Restaurant Partner Menu and Price List
                      </p>

                      <div>
                        <ol>
                          <li>
                            The Restaurant Partner acknowledges and agrees that
                            the Platform will provide the following payment
                            mechanisms to the Customers for the payment of the
                            Order Value:
                            <br />
                            <br /> A. Cash on Services <br /> <br />
                            B. Electronic Payment Mechanism.
                            <br />
                            <br /> C. Redemption of vouchers and/or discount
                            coupons (if any) approved by Sortyfy.
                            <br />
                            <br /> D. Redemption of Reward Points (if any)
                            approved by Sortyfy and accepted by Restaurant
                            Partner
                          </li>
                          <li>
                            The Restaurant Partner acknowledges and agrees that
                            Sortyfy will provide the Restaurant Partner with a
                            monthly invoice within a period of 7 (seven) days
                            from the last date of the preceding month for the
                            Service fee, Payment Mechanism Fee, refund charges,
                            One Time Sign Up Fee (If Applicable) and other
                            amounts, charges that is payable by the Restaurant
                            Partner to Sortyfy in respect of the applicable
                            Orders.
                          </li>
                          <li>
                            Invoices will be sent to the Restaurant Partner by
                            email. All invoices shall be issued from the
                            respective state registered office of Sortyfy from
                            where the Services are being performed by Sortyfy in
                            order to comply with the provisions of Goods &
                            Services Tax (GST) laws applicable in India. The
                            list of respective state Sortyfy’s registered
                            offices of Sortyfy across India is available at
                            Licenses.
                          </li>
                          <li>
                            The Restaurant Partner acknowledges and agrees that
                            all amounts that are payable to Sortyfy under these
                            Terms shall be exclusive of the applicable taxes and
                            that all applicable taxes will be charged
                            separately.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>
                        Obligations of Parties in Case of Online Payment Orders
                      </p>
                      <p>
                        The Restaurant Partner must meet all of the following
                        requirements when it receives an online payment Order:
                      </p>
                      <div>
                        <ol>
                          <li>
                            Ensure that it does not receive any additional
                            payment from a Customer (including but not limited
                            to payment by cash) when payment has been made
                            online by a Customer.
                          </li>
                          <li>
                            Follow all special instructions contained on the
                            Order receipt or as communicated by Service
                            Operator; and
                          </li>
                          <li>
                            If the Restaurant Partner has not complied with the
                            Services instructions (as set forth in this Order
                            receipt) or has supplied bad quality Goods to the
                            Customer, whereby Sortyfy has (pursuant to the
                            Customer’s complaint) been constrained to refund the
                            Order Value to the Customer ("Problem Order") in any
                            manner, the Restaurant Partner acknowledges and
                            agrees that the Restaurant Partner will not be paid
                            for such Problem Order and that if the Restaurant
                            Partner has already received the Order Value from
                            Sortyfy in respect of such Problem Order (subject to
                            the appropriate reductions under Clause 10), Sortyfy
                            will have a right in accordance with these Terms to
                            deduct or offset such amount from or against any
                            monies that are owed by Sortyfy to the Restaurant
                            Partner (in respect of future Orders) under these
                            Terms.
                          </li>
                          <li>
                            Restaurant Partner shall disclose all relevant
                            details pertaining to Problem Order(s) with Sortyfy,
                            if required by Sortyfy.
                          </li>
                          <li>
                            Sortyfy will communicate promptly with its bank if
                            it becomes aware of any fraud having been committed
                            by a customer.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>Payment Settlement Process</p>

                      <div>
                        <ol>
                          <li>
                            The Restaurant Partner acknowledges and agrees that
                            any Order Value which is collected by Sortyfy for,
                            and on behalf of, the Restaurant Partner in
                            accordance with these Terms, shall be passed on by
                            Sortyfy to the Restaurant Partner subject to the
                            deduction of the following amounts (as applicable)
                            by Sortyfy from such Order amount: A. Service fee
                            (For cash on Services orders and online paid Orders)
                            <br />
                            <br />
                            B. Amount of Payment Mechanism Fee due from
                            Restaurant Partner
                            <br />
                            <br />
                            C. Amount of tax collected by Sortyfy in case of
                            Restaurant Service provided by Restaurant Partner
                            though the Sortyfy Platform; and
                            <br />
                            <br />
                            D. Any other amount that are due to Sortyfy under
                            the Form or on account of other services availed by
                            Restaurant
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>
                        Partner from Sortyfy, to which specific consent of the
                        Restaurant Partner is accorded.
                      </p>

                      <div>
                        <ol>
                          <li>
                            The Parties acknowledge and agree that after the
                            deduction of the aforementioned amounts, Sortyfy
                            shall remit the Order Value due to the Restaurant
                            Partner on a 24 Hrs settlement basis(or within a
                            period of 2 bank working days if opted for by the
                            Restaurant Partner in the Form) from the date the
                            payment of the Order Value is received by Sortyfy.
                            For 24 Hrs settlement of the Order Value, the
                            Parties acknowledge and agree that after the
                            deduction of the aforementioned amounts, Sortyfy
                            shall remit the Order Value due to the Restaurant
                            Partner on a 24 Hrs settlement basis after allowing
                            reasonable time for adjustments towards Orders for
                            which the Customers have either refused to pay or
                            have claimed a refund, as applicable (but in
                            accordance with the guidelines prescribed by Reserve
                            Bank of India for payment systems and nodal account)
                            ("Payment Settlement Day"). The Payment Settlement
                            Day for Orders serviced from Monday to Sunday shall
                            be on or before 24 Hrs settlement. If the Payment
                            Settlement Day falls on a bank holiday it shall be
                            the following working day.
                          </li>
                          <li>
                            Notwithstanding anything to the contrary contained
                            in these Terms or the Form, the Restaurant Partner,
                            on behalf of itself and all its affiliates, hereby
                            unconditionally and irrevocably authorises Sortyfy
                            to set off, withhold and deduct any amounts owed by
                            the Restaurant Partner or its affiliates to any
                            Sortyfy Group Company under any agreement,
                            arrangement or understanding between the Sortyfy
                            Group Company and the Restaurant Partner or its
                            affiliates, from the Net Order Value, and apply such
                            amounts towards the dues owed by the Restaurant
                            Partner or its affiliates to the Sortyfy Group
                            Company. Without prejudice to the other provisions
                            of the Form or these Terms, and solely for the
                            purposes of the Clause, the Restaurant Partner
                            hereby agrees, acknowledges and confirms that the
                            amounts so set off, withheld and applied in the
                            aforesaid manner shall be deemed to form part of the
                            Service fee payable by the Restaurant Partner to
                            Sortyfy under the Form and these Terms. For purposes
                            of the provisions of this clause, the term Sortyfy
                            Group Company shall be deemed to include any of
                            Sortyfy's current and former, direct and indirect,
                            subsidiaries and/or controlled affiliates as well as
                            any successor to Sortyfy or all or any material
                            portion of the businesses and/or assets of Sortyfy
                            or any successor thereto or any of its direct and
                            indirect, subsidiaries and/or controlled affiliates.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div>
                      <p style={notes}>Charges</p>
                      <p>
                        In consideration of the Services offered by Sortyfy to
                        the Restaurant Partner, the Restaurant Partner
                        undertakes to pay to Sortyfy charges including Service
                        fee, Payment Mechanisn Fee, at the rates set out in the
                        Form.
                      </p>
                      <p>
                        The Restaurant Partner acknowledges and agrees that
                        where Sortyfy extends additional support services to the
                        Restaurant Partner and/or Customers and incurs
                        corresponding support costs, or where Sortyfy issues
                        refunds to the Customers on account of acts or omissions
                        attributable to the Restaurant Partner, including but
                        not limited to, frequent rejection or time-out of
                        Order(s), delay in accepting or handing over the
                        Order(s), poor quality food, missing or incorrect item,
                        poor quality packaging, etc, as may be communicated to
                        the Restaurant Partner in the periodic reports, Sortyfy
                        reserves the right to charge additional amount(s) as
                        highlighted below:
                      </p>

                      <div style={tableborder}>
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                Description
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                Charges and Deductions
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                Effective from
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                Charges for Orders requiring support
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                If Orders requiring Sortyfy Team support: <br />{" "}
                                Free - service fee for first 30 days. INR 10.00
                                per Order for all Orders requiring support
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  color: "red",
                                }}
                              >
                                {moment(pdfdata?.date).format(
                                  "DD,MMM,y hh:mm a"
                                )}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                Exception for new Restaurant for two (2) weeks
                                from the Effective Date. The Restaurant Partner
                                agrees that this amount will be deducted from
                                its ongoing payouts.
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                Charges for Restaurant Partner rejected Orders
                                [2]
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                If Restaurant Partner Cancels Orders (After
                                Accepting) or don’t accept within 15 Minutes:
                                INR.50 Per Cancelled or unaccepted bookings.
                                <br />
                                <br />
                                If weekly Restaurant Partner Cancels Orders
                                (After Accepting) or don’t accept within 15
                                Minutes, exceed more than 2% of weekly Order
                                volume: INR.250 will be charged to the
                                Restaurant Partner for the relevant week.
                              </td>

                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  color: "red",
                                }}
                              >
                                {moment(pdfdata?.date).format(
                                  "DD,MMM,y hh:mm a"
                                )}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                Exception for new Restaurant for two (2) weeks
                                from the Effective Date. The Restaurant Partner
                                agrees that this amount will be deducted from
                                its ongoing payouts.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <ol>
                        <li>
                          Orders requiring support: Orders where support teams
                          extend additional support to mitigate customer
                          escalations including but not limited to, delay in
                          accepting or handing over the Order(s), poor quality
                          food, missing or incorrect item, poor quality
                          packaging, etc.
                        </li>
                        <li>
                          Restaurant Partner rejected Orders: Orders which are
                          not accepted (i.e., on account of rejection or
                          inaction resulting in time-out)by the Restaurant
                          Partner or accepted but not fulfilled by the
                          Restaurant Partner.
                        </li>
                      </ol>
                      <p>
                        From time to time, Sortyfy may change the fees for the
                        Services, including without limitation the Service fee
                        rates, Payment Mechanism Fee, any other charges/fees or
                        include any additional charges/fee, with intimation to
                        the Restaurant Partner seven (7) days prior to the date
                        such change(s) or additional charges are to take effect.
                      </p>
                    </div>

                    <div>
                      <center>
                        <p style={greenheadingBig}>Taxes</p>
                      </center>
                      <p>
                        Notwithstanding anything to the contrary herein, the
                        Restaurant Partner is, and will be, responsible for all
                        taxes, payments, fees, and any other liabilities
                        associated with the computation, payment, and collection
                        of taxes in connection with Customer Orders for supply
                        other than Restaurant Service and the Restaurant
                        Partner’s use of the Platform and Sortyfy Services.
                        Sortyfy may charge and collect applicable taxes from
                        Customers on behalf of the Restaurant Partner in
                        accordance with instructions provided by the Restaurant
                        Partner and/or applicable law; and, in which case,
                        Sortyfy will collect such tax solely on behalf of the
                        Restaurant Partner and shall pay such amount collected
                        to the Restaurant Partner. The Restaurant Partner shall
                        be solely responsible for verifying amounts collected,
                        filing the appropriate tax returns, and remitting the
                        proper amount to the appropriate tax authorities. Taxes
                        shall include all applicable taxes due in relation to
                        the sale of food and beverages, including pick-up and
                        Table Booking (if applicable), by the Restaurant
                        Partner. It is clarified that Sortyfy will not be liable
                        for payment of any Taxes that the Restaurant Partner is
                        liable to pay in connection with supply other than
                        Restaurant Services which shall be provided by the
                        Restaurant Partner to the Customers in accordance with
                        these Terms and that the Restaurant Partner hereby
                        undertakes to indemnify, defend and hold harmless,
                        Sortyfy and each of its affiliates and (as applicable)
                        all of their directors, officers employees,
                        representatives and advisors against any tax liability
                        that may arise against Sortyfy on account of the
                        non-payment of Taxes by the Restaurant Partner under
                        these Terms. Restaurant Partner will be required to
                        deposit relevant taxes, including tax deducted at source
                        (TDS) on the service fee payable to Sortyfy. However, in
                        case where Sortyfy withholds its Service fee before
                        remitting the settlement dues to the Restaurant Partner,
                        Sortyfy shall refund the TDS to the Restaurant Partner
                        subject to submission of the TDS certificate on a
                        quarterly basis within sixty (60) days from the end of
                        the quarter.
                      </p>

                      <p style={comonHeading}>
                        Tax paid by Sortyfy on Restaurant Service under GST laws
                        ('Tax u/s 9(5') of Central Goods and Services Tax Act,
                        2017:
                      </p>
                      <p>
                        W.e.f. 01 January 2022, notwithstanding anything to the
                        contrary herein, Sortyfy is, and will be, responsible
                        for payment and collection of taxes in connection with
                        Customer Orders of Restaurant Service. Sortyfy will
                        collect applicable taxes from Customers on behalf of the
                        Restaurant Partner in accordance with GST laws and
                        deposit the same to the proper amount to the appropriate
                        tax authorities. Taxes shall include all applicable
                        taxes due in relation to the supply of Restaurant
                        Service including pick-up and Table Booking (if
                        applicable), by the Restaurant Partner. Restaurant
                        Partner(s) will be required to deposit relevant taxes,
                        including tax deducted at source (TDS) on the service
                        fee payable to Sortyfy. However, in case where Sortyfy
                        withholds its Service fee before remitting the
                        settlement dues to the Restaurant Partner, Sortyfy shall
                        refund the TDS to the Restaurant Partner subject to
                        submission of the TDS certificate on a quarterly basis
                        within sixty (60) days from the end of the quarter.
                      </p>
                      <p>
                        For the purpose of clarification, as per Section 9(5) of
                        Central Goods and Services Tax Act, 2017, with effect
                        from 1 January 2022, tax on supply of specified
                        Restaurant Service supplied by Restaurant(s) through
                        e-commerce platform shall be paid by such e-commerce
                        operators.
                      </p>

                      <p style={comonHeading}>Tax collected at source:</p>
                      <p>
                        W.e.f. 01st October, 2018, Sortyfy is required to
                        collect taxes at source on Gross Sales (less applicable
                        taxes charged by Restaurant(s) on Supply of Food and
                        Beverages Items other than supply of Restaurant Service,
                        at such rates as required by the applicable tax laws
                        (“TCS”). The TCS shall be collected on the date of
                        acceptance of Order. The Restaurant Partner shall be
                        solely responsible to provide correct GSTIN details to
                        Sortyfy and reconcile the TCS with the tax statements
                        provided by Sortyfy from time to time, as required by
                        applicable laws. Sortyfy shall remit TCS to the
                        respective Central Government and State Government/Union
                        Territory and such remittance(s) shall be considered as
                        complete fulfillment of Sortyfy's obligations in this
                        regard. TheRestaurant Partner may be eligible to claim
                        TCS credit on the basis of tax returns filed by it with
                        the relevant government/statutory/tax authorities.
                        Restaurant Partner acknowledges and agrees that in the
                        event of any discrepancy between the tax returns/entries
                        filed by Restaurant Partner and those filed by Sortyfy,
                        the tax returns/entries present and filed by Sortyfy
                        shall have a precedence over the tax returns/entries
                        filed by Restaurant Partner.
                      </p>
                      <p>
                        Sortyfy shall share a monthly TCS statement along with
                        invoice with Restaurant Partner to report transactions
                        undertaken during the month in its applicable returns.
                      </p>
                      <p style={comonHeading}>
                        Tax Deducted at Source under Income Tax Act (‘TDS u/s
                        194-O’):
                      </p>
                      <p>
                        W.e.f. 01st October, 2020, Sortyfy is required to deduct
                        taxes at source from the amount for all Orders that are
                        settled via Sortyfy’s Platform. TDS u/s 194-O shall be
                        applied on Gross Sales (less applicable taxes charged by
                        the Restaurant) as per the applicable tax law on the
                        rate as applicable and amended from time to time.
                        Sortyfy hereby clarifies that TDS u/s 194-O shall be
                        deducted under the Payment Settlement Process set out in
                        the Form and these Terms.
                      </p>

                      <p>
                        The Restaurant Partner shall be solely responsible to
                        provide correct PAN details to Sortyfy and reconcile the
                        income tax deducted with the order transaction report
                        and certificates provided by Sortyfy from time to time.
                        Sortyfy shall remit income tax deducted to the
                        respective Government account and report against the PAN
                        as available on Sortyfy records. Such remittance(s) and
                        reporting shall be considered as complete fulfilment of
                        Sortyfy's obligations in this regard.
                      </p>

                      <p>
                        Sortyfy will share a quarterly TDS certificate with
                        Restaurant Partner as per applicable tax law. The
                        Restaurant Partner may be eligible to claim credit of
                        the income tax deducted on the basis of tax returns
                        filed by it with the relevant government tax
                        authorities. Restaurant Partner acknowledges and agrees
                        that it is the Restaurant Partner’s responsibility to
                        reconcile and claim correct credit for the income tax
                        deducted for which the Restaurant Partner may be
                        eligible as per the applicable laws. Restaurant Partner
                        also agrees that in the event of any discrepancy between
                        the returns filed by the Restaurant Partner and those
                        filed by Sortyfy, the amount reported by Sortyfy shall
                        have a precedence over the tax returns filed by the
                        Restaurant Partner. <br />
                        Any discrepancy identified at the time of reconciliation
                        by the Restaurant Partner should be brought to the
                        attention of Sortyfy within a period of fifteen (15)
                        days from the date of receipt of TDS Certificate. Any
                        delay on this account will relieve Sortyfy of any
                        obligation to undertake a corrective action to resolve
                        the discrepancy. Under no circumstances, Sortyfy shall
                        entertain any discrepancy in the reported value after
                        31st July of the year following the financial year to
                        which the transactions were undertaken and reported.
                      </p>

                      <p>
                        Restaurant Partner agrees and acknowledges that Sortyfy
                        shall not be held liable in any manner in the event the
                        Restaurant Partner does not receive the benefit of
                        income tax deducted due to incorrect particulars
                        provided by the Restaurant Partner to Sortyfy.
                      </p>
                    </div>

                    <div>
                      <p style={notes}>Confidentiality</p>

                      <ol>
                        <li>
                          Other than for provision of Service(s) by Sortyfy,
                          Sortyfy does not share any other information of the
                          Restaurant Partner with third parties unless
                          requisitioned by government authorities.
                        </li>
                        <li>
                          Other than for the purpose of availing Service(s) from
                          Sortyfy, the Restaurant Partner must not disclose any
                          confidential information about Sortyfy, including but
                          not limited to these Terms, its business strategies,
                          pricing, revenues, expenses, Customer Data, and Order
                          information to third parties.
                        </li>
                      </ol>
                    </div>

                    <div>
                      <p style={notes}>Warranty and Indemnity</p>

                      <ol>
                        <li>
                          Restaurant Partner warrants that if the Restaurant
                          Partner ceases to do business, closes operations for a
                          material term or is otherwise unable to offer services
                          to Customers it will inform Sortyfy promptly. Where
                          the Restaurant Partner neglects to do so, whether by
                          omission or fault, Sortyfy will not be held
                          responsible for any liabilities, whether financial or
                          otherwise.
                        </li>
                        <li>
                          Restaurant Partner warrants that it will not offer for
                          sale any potentially hazardous food, alcoholic
                          beverage, tobacco product, or any other item
                          prohibited by law.
                        </li>
                        <li>
                          Sortyfy warrants that it will undertake its
                          obligations with reasonable skill and care.
                        </li>
                        <li>
                          Sortyfy does not guarantee or warrant that the
                          Platform, Application, Tablet, Sortyfy Device,
                          software, hardware or services will be free from
                          defects or malfunctions. If errors occur, it will use
                          its best endeavours to resolve these as quickly as
                          possible.
                        </li>
                        <li>
                          Restaurant Partner warrants that it complies and will
                          remain compliant with the Food Safety and Standards
                          Act, 2006, Legal Metrology Act, 2009 and the
                          applicable rules and regulations made thereunder and
                          any other requirements or applicable law in the
                          relevant State or Territory and all other applicable
                          legislation, regulations or standards.
                        </li>
                        <li>
                          Restaurant Partner warrants to procure and keep valid
                          during the subsistence of these Terms, all requisite
                          licenses and/or registrations thereunder and provide
                          copy of such licenses and/or registrations to Sortyfy
                          prior to availing the Services.
                        </li>
                        <li>
                          <p style={comonHeading}>
                            {" "}
                            The Restaurant Partner agrees to indemnify and hold
                            Sortyfy (and its directors, officers, agents,
                            representatives and employees) harmless from and
                            against any and all claims, suits, liabilities,
                            judgments, losses and damages arising out of or in
                            connection with any claim or suit or demand:
                          </p>
                          <br />
                          <br />
                          A. by a Customer (or any party on whose behalf a
                          Customer has been acting), for reasons not
                          attributable to the Service; <br />
                          <br />
                          B. By a Customer (or any party on whose behalf a
                          Customer has been acting) or any third party In
                          respect of, arising out of or relating to the Content,
                          Information or material provided by the Restaurant
                          Partner to Sortyfy, to be listed on the Platform.br
                          <br />
                          <br />
                          C. in respect of, arising out of, or in connection
                          with, Restaurant Services (or any other services
                          actually or purportedly offered in respect of or
                          connected to the Restaurant Services) and Services of
                          such Restaurant Services when undertaken by the
                          Restaurant Partner;
                          <br />
                          <br />
                          D. in respect of or connected to the collection or
                          payment of applicable taxes in any way connected to
                          these Terms or any matter or goods or services under
                          or arising from it (e.g. Services);
                          <br />
                          <br />
                          E. in respect of quality of the Restaurant Services
                          provided by the Restaurant Partner;
                          <br />
                          <br />
                          F. in respect of the warranties provided by the
                          Restaurant Partner in Clause 13 (Warranty and
                          Indemnity) hereto;
                          <br />
                          <br />
                          G. relating to harm caused by the Restaurant Partner
                          supplying any unsafe Restaurant Services, any product
                          failure, defect or hazard in any Restaurant Services
                          supplied or sold by the Restaurant Partner or
                          inadequate instructions or warnings provided to
                          Customers pertaining to any hazards arising from or
                          associated with the use of any Restaurant Services
                          supplied or sold by the Restaurant Partner;
                          <br />
                          <br />
                          H. brought either by Sortyfy and/or third party on
                          account of misuse, abuse, cheating, fraud,
                          misrepresentation carried out by the Restaurant
                          Partner;
                          <br />
                          <br />
                          I. in respect of, arising out of or in connection of
                          any use of the Customer Data, not in accordance with
                          the Terms and/or the applicable law;
                          <br />
                          <br />
                          J. for any misleading or incorrect or false,
                          information or data provided by the Restaurant
                          Partner;
                          <br />
                          <br />
                          K. misuse and/ or unauthorized or fraudulent use of
                          Sortyfysoftware/ Sortyfy Device (If Any);
                          <br />
                          <br />
                          L. Damage or loss to the SortyfySoftware / Device;
                        </li>
                        <li>
                          The Restaurant Partner acknowledges that it supplies
                          certain rights to Sortyfy to enable Sortyfy to provide
                          services to the Customer. Sortyfy will not be liable
                          for any applicable taxes liability in respect of
                          Supply of Foods and Beverages Items other than
                          Restaurant Service by Restaurant Partner to Customers
                          and the Restaurant Partner hereby indemnifies Sortyfy
                          against any applicable taxes it may become liable for
                          arising out of such transactions.
                        </li>
                      </ol>
                    </div>

                    <div>
                      <p style={notes}>Customer Data</p>

                      <p>
                        The Restaurant Partner agrees that the Restaurant
                        Partner will only use the Customer Data in fulfilling
                        the applicable Customer Order and in complying with the
                        Restaurant Partner's obligations in this Form, and the
                        Restaurant Partner agrees that Customer Data will not be
                        used to enhance any file or list of the Restaurant
                        Partner or any third party. The Restaurant Partner
                        represents, warrants, and covenants that it will not
                        resell, broker or otherwise disclose any Customer Data
                        to any third party, in whole or in part, for any purpose
                        whatsoever. The Restaurant Partner agrees it shall not
                        use the Customer Data for sending any unsolicited
                        marketing message, announcements and for feedback
                        purposes, and shall be solely liable to ensure that any
                        third party with whom Customer Data is shared complies
                        with the restrictions set forth herein. The Restaurant
                        Partner agrees that it will not copy or otherwise
                        reproduce any Customer Data other than for the purpose
                        of fulfilling the applicable Customer Order. The
                        Restaurant Partner (and any other persons to whom the
                        Restaurant Partner provides any Customer Data) will
                        implement and comply with reasonable security measures
                        in protecting, handling, and securing the Customer Data.
                        If any Customer Data is collected by the Restaurant
                        Partner (or otherwise on its behalf), the Restaurant
                        Partner shall ensure that it (and any applicable third
                        parties) adopt, post, and process the Customer Data in
                        conformity with an appropriate and customary
                        <a
                          href={`https://sortyfy.com/privacy_policy.html `}
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        . For purposes of this Form, the restrictions set forth
                        herein on the Restaurant Partner's use of Customer Data
                        do not apply to: (a) data from any Customer who was a
                        customer of Restaurant Partner prior to the Restaurant
                        Partner using the Platform or the Sortyfy Services, but
                        only with respect to data that was so previously
                        provided to the Restaurant Partner by such Customer; or
                        (b) data supplied by a customer directly to the
                        Restaurant Partner who becomes a customer of the
                        Restaurant Partner and who explicitly opts in to receive
                        communications from the Restaurant Partner for the
                        purposes for which such Customer Data will be used by
                        Restaurant Partner; and, provided in all cases, that the
                        Restaurant Partner handles and uses such Customer Data
                        in compliance with applicable Laws and the Restaurant
                        Partner's posted{" "}
                        <a
                          href={`https://sortyfy.com/privacy_policy.html `}
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>

                    <div>
                      <p style={notes}>Term and Termination</p>

                      <p>
                        The arrangement between parties shall start on the
                        Execution Date and unless terminated earlier under this
                        clause shall continue indefinitely. Either Party may
                        terminate the arrangement, with or without cause, at any
                        time upon seven (7) days prior written notice to the
                        other party.
                      </p>
                      <p style={comonHeading}>
                        Sortyfy may terminate the arrangement or suspend the
                        Services of the Restaurant Partner with immediate effect
                        if:
                      </p>
                      <ul>
                        <li>
                          the Restaurant Partner fails to conduct its business
                          in accordance with these Terms and /or in accordance
                          with the information given to Sortyfy, such as
                          Restaurant Partner proprietary rights, opening hours,
                          Services areas, Services conditions, nature of food
                          served or prices;
                        </li>

                        <li>
                          {" "}
                          the user experience for the Restaurant Partner is not
                          found satisfactory as per the Sortyfy standards;
                        </li>
                        <li>
                          {" "}
                          for a continuous period of 14 days, the Restaurant
                          Partner fails to Service Order(s) which are not
                          fraudulent or unintentional;
                        </li>
                        <li>
                          {" "}
                          upon the happening of any of the insolvency events
                          such as bankruptcy, appointment of receiver,
                          administrator, liquidator, winding up, dissolution;
                        </li>
                        <li>
                          {" "}
                          Sortyfy identifies any fraudulent or suspicious
                          activity on the Restaurant Partner’s account;
                        </li>
                        <li>
                          {" "}
                          the Restaurant Partner fails to comply with Applicable
                          Law and/or these Terms,; and/or
                        </li>
                        <li>
                          in case Sortyfy conducts any investigation to ensure
                          Restaurant Partner’s compliance with the Applicable
                          Law and/or these Terms.
                        </li>
                      </ul>

                      <p>
                        Parties may terminate their arrangement with immediate
                        effect by notice in writing to the other party if the
                        other party commits a material breach, which, in the
                        case of a material breach capable of remedy, is not
                        remedied within 14 days after written notice is given to
                        the breaching party, specifying the default. Termination
                        of the arrangement:
                      </p>

                      <ul>
                        <li>
                          in accordance with its Terms, shall not affect the
                          accrued rights or liabilities of the parties at the
                          date of termination;
                        </li>
                        <li>
                          and shall have no effect on:
                          <br />
                          i. The validity of Services already issued to
                          Customers; or <br />
                          ii. Restaurant Partner’s obligations to pay for
                          Services availed in accordance with this Agreement.
                        </li>
                      </ul>
                      <p>
                        Sortyfy, at its sole discretion, reserves the right to
                        suspend and/or terminate the Services immediately with a
                        prior notice to the Restaurant Partner on account of any
                        alleged or threatened or actual (a) suspicious activity;
                        and /or (b) breach of any intellectual property right of
                        Sortyfy or any third party by the Restaurant Partner;
                        and/or (c) any false misrepresentation by the Restaurant
                        Partner; and/or (d).fraudulent activity. The Restaurant
                        Partner hereby agrees and acknowledges that in addition
                        to the aforementioned right to terminate and/or suspend
                        the Services, Sortyfy shall also have the right to
                        withhold, set off and deduct any payments that may be
                        due to the Restaurant Partner from Sortyfy. Without
                        prejudice to the other provisions of the Form or these
                        Terms, and solely for the purposes of this Clause, the
                        Restaurant Partner hereby agrees, acknowledges and
                        confirms that the amounts so set off, withheld and
                        applied in the aforesaid manner shall be deemed to form
                        part of the Service fee payable by the Restaurant
                        Partner to Sortyfy under the Form and these Terms.
                      </p>
                    </div>

                    <div>
                      <p style={notes}>Notice requirements</p>

                      <p>
                        Factors that prevent you from fulfilling your
                        obligations towards Sortyfy or Customers should promptly
                        be reported to Sortyfy by contacting the account manager
                        appointed by Sortyfy or by writing an email to
                        admin@Sortyfy.com.
                      </p>
                    </div>

                    <div>
                      <p style={notes}>Disclaimers</p>

                      <p>
                        To the fullest extent permitted by law, Sortyfy and its
                        affiliates, and each of their respective officers,
                        directors, members, employees, and agents disclaim all
                        warranties, express or implied, in connection with this
                        Form, the Platform and the Sortyfy services and any use
                        thereof, including, without limitation, the implied
                        warranties of merchantability, fitness for a particular
                        purpose and non-infringement. Sortyfy makes no
                        warranties or representations about the accuracy or
                        completeness of the content and data on the Platform or
                        the Sortyfy services' content or the content of any
                        other websites linked to the website, and assumes no
                        liability or responsibility for any (a) errors,
                        mistakes, or inaccuracies of content and materials, (b)
                        personal injury or property damage, of any nature
                        whatsoever, resulting from the Restaurant Partner's
                        access to and use of the Platform and the Sortyfy
                        services, (c) any unauthorized access to or use of
                        Sortyfy' servers and/or any and all personal information
                        and/or financial information stored therein, (d) any
                        interruption or cessation of transmission to or from the
                        website or otherwise with respect to the Sortyfy
                        services, (e) any bugs, viruses, trojan horses, or the
                        like which may be transmitted to or through the website
                        or the Sortyfy services by any third party, and/or (f)
                        any errors or omissions in any content and materials or
                        for any loss or damage of any kind incurred as a result
                        of the use of any content posted, transmitted, or
                        otherwise made available via the website or the Sortyfy
                        Services.
                      </p>
                    </div>

                    <div>
                      <p style={notes}>Limitation of Liability</p>

                      <p>
                        For the purposes of this clause, "Liability" means
                        liability in or for breach of contract, negligence,
                        misrepresentation, tortious claim, restitution or any
                        other cause of action whatsoever relating to or arising
                        under or in connection with this Form, including
                        liability expressly provided for under this Form or
                        arising by reason of the invalidity or unenforceability
                        of any term of this Form. Sortyfy does not exclude or
                        limit Liability for any Liability that cannot be
                        excluded by law. Subject to the preceding sentence,
                        Sortyfy shall not be under any Liability for loss of
                        actual or anticipated profits, loss of goodwill, loss of
                        business, loss of revenue or of the use of money, loss
                        of contracts, loss of anticipated savings, loss of data
                        and/or undertaking the restoration of data, fraudulent
                        Orders, any special, indirect or consequential loss, and
                        such liability is excluded whether it is foreseeable,
                        known, foreseen or otherwise. For the avoidance of any
                        doubt, this clause shall apply whether such damage or
                        loss is direct, indirect, consequential or otherwise.
                        Although Sortyfy will use its best endeavours to ensure
                        that the unintentional operational errors do not occur,
                        Sortyfy cannot provide any warranty or guarantee in this
                        regard. Notwithstanding anything to the contrary herein
                        set out, Sortyfy’s aggregate liability under this Form
                        shall not exceed the total value of the Order under
                        which the claim arose.
                      </p>
                    </div>

                    <div>
                      <p style={notes}>Miscellaneous</p>
                      <ol>
                        <li>
                          Governing Law and Dispute Resolution: This Form shall
                          be governed by the Laws of India, for the time being
                          in force and the courts of New Delhi shall have the
                          exclusive jurisdiction to preside over matters arising
                          hereunder. Parties shall first endeavour to resolve
                          their disputes amicably within fifteen (15) days from
                          the date on which the dispute was first notified.
                          Failing which, the dispute shall be referred to court.
                        </li>

                        <li>
                          Waiver: The failure of either party to assert any of
                          its rights under the Form, including, but not limited
                          to, the right to terminate the Form in the event of
                          breach or default by the other party, will not be
                          deemed to constitute a waiver by that party of its
                          right thereafter to enforce each and every provision
                          of the Form in accordance with these Terms.
                        </li>
                        <li>
                          Invalidity or unenforceability of any provision of or
                          right arising pursuant to these Terms shall not
                          adversely affect the validity or enforceability of the
                          remaining obligations or provisions.
                        </li>
                        <li>
                          No third-party rights: No term of this Form shall be
                          enforceable by a third party.
                        </li>
                        <li>
                          No assignment: The Restaurant Partner must not assign,
                          transfer, charge or otherwise encumber, create any
                          trust over or deal in any manner with this Form or any
                          right, benefit or interest under it, nor transfer,
                          novate or sub-contract any of the Restaurant Partner’s
                          obligations under it.
                        </li>
                        <li>
                          Independent contractors: The Form does not create any
                          agency, employment, partnership, joint venture, or
                          other joint relationship. Sortyfy and the Restaurant
                          Partner are independent contractors and neither has
                          any authority to bind the other.
                        </li>
                        <li>
                          Change of control: The Restaurant Partner acknowledges
                          that the business and assets of Sortyfy may be sold in
                          the future and consents to the transfer or disclosure
                          of its personal Information and this Form to any
                          purchaser of the business of Sortyfy or its assets if
                          that outcome occurs.
                        </li>
                        <li>
                          Acceptance to Sortyfy’s{" "}
                          <a
                            target="_blank"
                            href={`https://sortyfy.com/privacy_policy.html `}
                          >
                            Privacy Policy
                          </a>
                          : by signing the Form, the Restaurant Partner
                          acknowledges and agrees to be bound by Sortyfy’s{" "}
                          <a
                            href={`https://sortyfy.com/privacy_policy.html `}
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                          . Restaurant Partner will immediately notify Sortyfy
                          if it becomes aware of or suspects any unauthorized
                          use or access to the user data or any other
                          Confidential Information of Sortyfy, and shall
                          co-operate with Sortyfy in investigation of such
                          breach and the mitigation of any damage.
                        </li>
                      </ol>
                    </div>

                    <div>
                      <p style={notes}>Miscellaneous</p>
                      <p>
                        Sortyfy may modify these Terms from time to time, and
                        any such changes will (i) be reflected on the Website,
                        and (ii) be effective immediately upon the changes being
                        reflected on the Platform. The Restaurant Partner agrees
                        to be bound to any such changes or modifications and
                        understands and accepts the importance of regularly
                        reviewing these Terms as updated on the Platform.
                      </p>
                      <p>
                        {" "}
                        Further, in the event Sortyfy upgrades, modifies or
                        replaces the Services ("Service Modifications") offered
                        to the Restaurant Partner, Sortyfy will notify the
                        Restaurant Partner prior to making the same and give the
                        Restaurant Partner the opportunity to review and comment
                        on the Service Modifications before continuing to use
                        the Service or any alternative service offered by
                        Sortyfy. The Service Modifications will also be
                        reflected on the Platform. If the Restaurant Partner
                        continues to use the Service or any alternate service
                        offered by Sortyfy, following any notice of the Service
                        Modifications, it shall constitute the Restaurant
                        Partner’s acceptance of such Service Modifications.
                      </p>

                      <p>
                        Last updated on{" "}
                        {moment(
                          moment(pdfdata?.date).format("DD,MMM,y hh:mm a")
                        ).format("DD,MMM,y hh:mm a")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFGenerator;
