import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { upperCaseByWord } from "../../../util/constant";
import ReadOnlyRow from "../Menu/ReadOnlyRow";
import Breadcrumb from "../../Breadcrumb";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const OrderContent = () => {
  const { orderDetails } = useSelector((state) => state.orders);
  const [orderDetail, setOrderDetail] = useState({});
  const { lastID } = useParams();
  const [ids, setIds] = useState("");
  useEffect(() => {
    if (orderDetails) {
      setOrderDetail(orderDetails);
    }
  }, [orderDetails]);
  useEffect(() => {
    setIds(lastID ? lastID : "");
  }, []);

  console.log("tusharl ", orderDetail);
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title="Order Details"
            links={[
              { to: "/", name: "Home" },
              { to: `/orders/${ids}`, name: "Orders" },
            ]}
          />
        </div>
        <div className="col-md-12">
          <div className="ms-panel">
            {/* <div className="ms-panel-header header-mini">
              <div className="d-flex justify-content-between">
                <h6>Order</h6>
                <h6>#135178</h6>
              </div>
            </div> */}
            <div className="ms-panel-body">
              {/* Invoice To */}
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="invoice-address">
                    <h3>Order: </h3>
                    {/* <h5>{upperCase(orderDetails?.userName)}</h5> */}
                    <p>
                      Order Status :
                      {orderDetail?.bookingStatus && (
                        <span
                          className={
                            orderDetail.bookingStatus === "decline"
                              ? "badge badge-dark ml-1"
                              : orderDetail.bookingStatus === "accepted"
                              ? "badge badge-success ml-1"
                              : "badge badge-primary ml-"
                          }
                        >
                          {""}
                          {upperCaseByWord(
                            orderDetail.bookingStatus == "cancel"
                              ? "Cancelled"
                              : orderDetail.bookingStatus == "completed" &&
                                orderDetail.isVerified == "no"
                              ? "Upcoming"
                              : orderDetail.bookingStatus
                          )}
                        </span>
                      )}
                    </p>
                    <p className="mb-0">{orderDetail?.address}</p>
                    <p className="mb-0">{orderDetail?.city}</p>
                    <p className="mb-0">{orderDetail?.country}</p>
                    {orderDetail?.menu?.image &&
                      orderDetail?.bookingStatus == "completed" && (
                        <p className="mb-0">
                          {" "}
                          <img
                            className="image-display"
                            src={orderDetail?.menu?.image}
                          />
                        </p>
                      )}
                  </div>
                </div>
                <div className="col-md-6 text-md-right">
                  <ul className="invoice-date">
                    <li>Booking Date : {orderDetail?.bookingDateTime}</li>
                    {/* <li>Due Date : Sunday, April 19 2021</li> */}
                  </ul>
                </div>
              </div>
              {/* Invoice Table */}
              <div className="ms-invoice-table table-responsive mt-5">
                <table className="table table-hover text-right thead-light">
                  <thead>
                    <tr className="text-capitalize">
                      <th className="text-center w-5">id</th>
                      <th className="text-left">Outlet</th>
                      <th className="text-left">Guest Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">{1}</td>
                      <td className="text-left">{orderDetail?.outletName}</td>
                      <td>
                        {orderDetail?.userName
                          ? upperCaseByWord(orderDetail.userName)
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>Total Guests:</td>
                      <td>{orderDetail?.totalGuests}</td>
                    </tr>
                  </tfoot>
                </table>
                <br />
                {orderDetail &&
                orderDetail?.promotionDetail &&
                Object.keys(orderDetail.promotionDetail).length ? (
                  <>
                    <h3>Promotion Detail: </h3>
                    <div className="ms-invoice-table table-responsive mt-5">
                      <table className="table table-hover text-right thead-light">
                        <thead>
                          <tr className="text-capitalize">
                            <th className="text-center w-5">id</th>
                            <th className="text-left">Title</th>
                            <th className="text-left">Price (per person)</th>
                            <th className="text-left">Discount(%)</th>
                            <th className="text-left">Image</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">{1}</td>
                            <td className="text-left">
                              {orderDetail?.promotionDetail?.promotionTitle
                                ? upperCaseByWord(
                                    orderDetail?.promotionDetail?.promotionTitle
                                  )
                                : ""}
                            </td>
                            <td className="text-left">
                              {orderDetail?.promotionDetail?.promotionPrice
                                ? orderDetail?.promotionDetail?.promotionPrice
                                : ""}
                            </td>
                            <td className="text-left">
                              {orderDetail?.promotionDetail?.promotionDiscount
                                ? orderDetail?.promotionDetail
                                    ?.promotionDiscount
                                : ""}
                            </td>
                            <td className="text-left">
                              {orderDetail?.promotionDetail?.promotionImage ? (
                                <img
                                  src={
                                    orderDetail?.promotionDetail?.promotionImage
                                  }
                                  alt={
                                    orderDetail?.promotionDetail?.promotionImage
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <>
                  {orderDetail?.menu ? (
                    <>
                      {/* <h3>Menu: </h3> */}
                      <div className="ms-invoice-table table-responsive mt-5">
                        <h5> Order # : {orderDetail?.menu?.orderNumber}</h5>
                        <table className="table table-hover text-right thead-light">
                          <caption>Menus</caption>
                          <thead>
                            <tr className="text-capitalize">
                              <th className="text-center w-5">id</th>
                              <th className="text-left">description</th>
                              <th className="text-left">qty</th>

                              <th className="text-left">total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetail &&
                              orderDetail?.menu?.menuItem?.map(
                                (menu, index) => (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-left">{menu?.name}</td>
                                    <td>{menu?.quantity}</td>
                                    <td>{menu?.amount}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                          {orderDetail?.menu?.type != "table" ? (
                            <tfoot>
                              <tr>
                                <td colSpan={3}>Gross Amount: </td>
                                <td>{orderDetail?.menu?.grossAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  <ul>
                                    <li>{orderDetail?.menu?.outletDiscount}</li>
                                    <li>
                                      {" "}
                                      {orderDetail?.menu?.sortyfyDiscount}
                                    </li>
                                  </ul>
                                </td>

                                <td>{orderDetail?.menu?.discountAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Net Amount: </td>
                                <td>
                                  {orderDetail?.menu?.grossAmount -
                                    orderDetail?.menu?.discountAmount}
                                </td>
                              </tr>
                              <tr>
                                <td colspan={3}>
                                  <table className="table table-hover text-right thead-light">
                                    <tbody>
                                      {orderDetail?.menu?.taxDetail &&
                                        orderDetail?.menu?.taxDetail?.map(
                                          (menu, index) => (
                                            <tr>
                                              <td>
                                                {menu?.title} {menu?.amount}%
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <table className="table table-hover text-right thead-light">
                                    <tbody>
                                      {orderDetail?.menu?.taxDetail &&
                                        orderDetail?.menu?.taxDetail?.map(
                                          (menu, index) => (
                                            <tr>
                                              <td>{menu?.charge}</td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Paybale Amount: </td>
                                <td>{orderDetail?.menu?.netAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Redeemed {orderDetail?.menu?.redeemPoint}{" "}
                                  Points:{" "}
                                </td>
                                <td>{orderDetail?.menu?.redeemAmount}</td>
                              </tr>
                              <td colSpan={3}> Final Amount:</td>
                              <td>
                                {orderDetail?.menu?.netAmount -
                                  orderDetail?.menu?.redeemAmount}
                              </td>
                            </tfoot>
                          ) : (
                            <tfoot>
                              <tr>
                                <td colSpan={3}> Table Booking Amount:</td>
                                <td>{orderDetail?.menu?.netAmount}</td>
                              </tr>
                            </tfoot>
                          )}
                        </table>
                      </div>
                    </>
                  ) : (
                    "No menu items found"
                  )}
                  <br />
                  <br />
                </>

                {orderDetail?.addonMenu?.length ? (
                  <>
                    <h3>Addon Menus: </h3>

                    {orderDetail?.addonMenu.map((addMenu, i) => {
                      return (
                        <div className="ms-invoice-table table-responsive mt-5">
                          <h5>
                            Order # :{" "}
                            <b> {orderDetail?.addonMenu?.[i]?.orderNumber}</b>
                          </h5>
                          <table className="table table-hover text-right thead-light">
                            <caption>Menus</caption>
                            <thead>
                              <tr className="text-capitalize">
                                <th className="text-center w-5">id</th>
                                <th className="text-left">description</th>
                                <th className="text-left">qty</th>

                                <th className="text-left">total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {addMenu.menuItem?.length &&
                                addMenu.menuItem?.map((m, index) => (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-left">{m?.name}</td>
                                    <td>{m?.quantity}</td>
                                    <td>{m?.amount}</td>
                                  </tr>
                                ))}
                            </tbody>
                            <tfoot>
                              {/* <tr>
                                <td colSpan={3}>Gross Amount: </td>
                                <td>{addMenu.grossAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  <ul>
                                    <li>{addMenu?.outletDiscount}</li>
                                    <li>{addMenu?.sortyfyDiscount}</li>
                                  </ul>
                                </td>
                                <td>{addMenu?.discountAmount}</td>
                              </tr>

                              <tr>
                                <td colSpan={3}>Tax: </td>
                                <td>{addMenu.taxAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Paid Amount:</td>
                                <td>{addMenu.netAmount}</td>
                              </tr> */}
                              <tr>
                                <td colSpan={3}>Gross Amount: </td>
                                <td>{addMenu?.grossAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  <ul>
                                    <li>{addMenu?.outletDiscount}</li>
                                    <li> {addMenu?.sortyfyDiscount}</li>
                                  </ul>
                                </td>

                                <td>{addMenu?.discountAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Net Amount: </td>
                                <td>
                                  {addMenu?.grossAmount -
                                    addMenu?.discountAmount}
                                </td>
                              </tr>
                              <tr>
                                <td colspan={3}>
                                  <table className="table table-hover text-right thead-light">
                                    <tbody>
                                      {orderDetail?.menu?.taxDetail &&
                                        orderDetail?.menu?.taxDetail?.map(
                                          (menu, index) => (
                                            <tr>
                                              <td>
                                                {menu?.title} {menu?.amount}%
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <table className="table table-hover text-right thead-light">
                                    <tbody>
                                      {addMenu?.taxDetail &&
                                        addMenu?.taxDetail?.map(
                                          (menu, index) => (
                                            <tr>
                                              <td>{menu?.charge}</td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Paybale Amount: </td>
                                <td>{addMenu?.netAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Redeemed {addMenu?.redeemPoint} Points:{" "}
                                </td>
                                <td>{addMenu?.redeemAmount}</td>
                              </tr>
                              <td colSpan={3}> Final Amount:</td>
                              <td>
                                {addMenu?.netAmount - addMenu?.redeemAmount}
                              </td>
                            </tfoot>
                          </table>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="invoice-buttons text-right">
                {" "}
                <Link to="#" className="btn btn-primary mr-2">
                  Print Invoice
                </Link>
                <Link to="#" className="btn btn-primary">
                  Send Invoice
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderContent;
