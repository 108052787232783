import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  getOrderDetails,
  getOrders,
  updateOrders,
  verifyOrder,
} from "../../../redux/slices/Orders";
import { upperCase } from "../../../constants";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { downloadExcel, getNewOrderFromSocket } from "../../../util/fn";
import useRequest from "../../../hooks/useRequest";
import { useParams } from "react-router-dom";
import AudioFile from "../../../assets/audio/audio.mp3";

const Ordertable = (props) => {
  const { hashKey } = useParams();

  const { request: requestAllOrders, response: responseAllOrders } =
    useRequest();
  // const { orders } = useSelector((state) => state.orders);
  const { outletList } = useSelector((state) => state.outlet);
  //Pagination
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState([]);
  const tableRef = useRef(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: localStorage.getItem("outletId"),
    startDate: "",
    endDate: "",
  });
  const [selectedOutletId, setSelectedOutletId] = useState(
    localStorage.getItem("outletId")
  );
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [outletName, setOutletName] = useState(null);
  const [socket, setSelectSocket] = useState();

  const [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState();

  useEffect(() => {
    requestAllOrders("POST", "order/orderHistory", {
      outletId: selectedOutletId
        ? selectedOutletId
        : localStorage.getItem("outletId"),
    });
  }, []);

  useEffect(() => {
    if (responseAllOrders) {
      setOrders(responseAllOrders);

      if (responseAllOrders?.data?.length) {
        setAllOrders(responseAllOrders?.data);
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(responseAllOrders?.data?.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(responseAllOrders?.data?.length / itemsPerPage));
      } else {
        setCurrentItems([]);
        setPageCount(0);
      }
    }
  }, [responseAllOrders, itemOffset, itemsPerPage]);

  const audio = new Audio(AudioFile);
  const handlePlay = async () => {
    try {
      await audio.play();
      requestAllOrders("POST", "order/orderHistory", {
        outletId: selectedOutletId
          ? selectedOutletId
          : localStorage.getItem("outletId"),
      });
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };
  useEffect(() => {
    const socket = getNewOrderFromSocket(
      selectedOutletId ? selectedOutletId : localStorage.getItem("outletId")
    );
    // localStorage.setItem('orderOutletId',selectedOutletId?selectedOutletId:localStorage.getItem('outletId'))

    if (socket) {
      socket.on("newOrder", (data) => {
        if (data) {
          const selOutletId = localStorage.getItem("outletId");
          if (data.outletId == selOutletId) {
            let newOrderData =
              allOrders && allOrders.length > 0
                ? allOrders
                : orders && orders.data
                ? orders.data
                : [];
            newOrderData.unshift(data);
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(newOrderData.slice(itemOffset, endOffset));
            setAllOrders(newOrderData);
          }
        }
      });

      socket.on("newOrderNotification", (data) => {
        if (data) {
          toast.success(data);
          handlePlay();
        }
      });
    }

    return () => {
      socket.off("newOrderNotification");
    };

    return () => {
      socket.off("newOrder");
    };
  }, [allOrders, orders, itemOffset, itemsPerPage, selectedOutletId]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orders?.data?.length;
    setItemOffset(newOffset);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  // Fetch items from another resources.
  const filterData = (e) => {
    const socket = getNewOrderFromSocket(e.target.value);
    setSelectedOutletId(e.target.value);
    localStorage.setItem("orderOutletId", e.target.value);
    setOutletName(
      outletList.find((el) =>
        el.outletId == e.target.value ? el.outletName : null
      )
    );

    setSelectedOutlet({
      ...selectedOutlet,
      [e.target.name]: e.target.value,
    });

    if (e.target.value == "") {
      setCurrentItems([]);
    } else {
      const startDate = document.getElementById("startDate").value;
      const endDate = document.getElementById("endDate").value;
      requestAllOrders("POST", "order/orderHistory", {
        outletId: e.target.value,
        startDate,
        endDate,
      });

      // dispatch(
      //   getOrders({
      //     startDate,
      //     endDate,
      //     outletId: e.target.value,
      //   })
      // );
    }
  };
  // useEffect(() => {
  //   dispatch(
  //     getOrders({
  //       outletId: localStorage.getItem("outletId"),
  //     })
  //   );
  // }, [dispatch]);

  const filterHandler = () => {
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    requestAllOrders("POST", "order/orderHistory", {
      outletId: selectedOutletId,
      startDate,
      endDate,
    });
    // dispatch(
    //   getOrders({
    //     startDate,
    //     endDate,
    //     outletId: selectedOutletId,
    //   })
    // );
    // dispatch(
    //   getPromotionList({
    //     startDate,
    //     endDate,
    //     outletId: selectedOutletId,
    //   })
    // ).then((res) => {
    //   // paginateEffect(res.payload.data);
    // });
  };

  const downloadExcelHandler = () => {
    let data = [];
    if (currentItems && currentItems.length > 0) {
      let count = 1;
      currentItems.forEach((obj) => {
        data.push({
          "Sr.": count,
          "User Name": obj?.userName,
          "User Phone Number": obj?.userMobile,
          Members: obj?.person,
          "Date & Time": obj?.date,
          "Booking For": obj?.bookingType,
          "Order Status": upperCase(
            obj.status == "cancel"
              ? "Cancelled"
              : obj.status == "completed" && obj.isVerified == "no"
              ? "Upcoming"
              : obj.status
          ),
          "Is Verified": upperCase(obj?.isVerified),
        });
        count = count + 1;
      });
    } else {
      data.push({
        "Sr.": "",
        "User Name": "",
        "User Phone Number": "",
        Members: "",
        "Date & Time": "",
        "Booking For": "",
        "Order Status": "",
        "Is Verified": "",
      });
    }
    downloadExcel(data, "Orders");
  };

  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };

  return (
    <div className="col-12">
      <div className="ms-panel">
        <div className="ms-panel-header">
          <h6>
            {" "}
            Order List {outletName ? `( ${outletName?.outletName} )` : ""}
          </h6>
        </div>
        <Row className="px-3 mt-3 mb-3">
          <Col md={6}></Col>
          <Col md={6}>
            {localStorage.getItem("vendorType") === "multiple" && (
              <div
                className="outletDropdown"
                style={{
                  padding: "0 30px 0",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div className="float-right d-flex">
                  <label htmlFor="outletId" className="form-label mr-3 mt-2">
                    Select Outlet
                  </label>
                  <div className="dropdown-group form-vendor-regi">
                    <select
                      className="form-control"
                      id="outletId"
                      name="outletId"
                      onChange={filterData}
                      value={selectedOutlet?.outletId}
                    >
                      <option value={""}>Please Select</option>
                      {outletList.length ? (
                        outletList.map((item) => {
                          return (
                            <option value={item.outletId}>
                              {upperCase(item.outletName)}
                            </option>
                          );
                        })
                      ) : (
                        <option value={0}> Not found</option>
                      )}
                    </select>
                    {/* <div className="invalid-feedback">
                  Please Select a valid Designation.
                </div> */}
                  </div>
                </div>
              </div>
            )}
          </Col>
          <Col md={4}>
            <div className="startDate">
              {/* date picker input */}
              <label htmlFor="validationCustom01" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                max={maxStartDate}
                onChange={handleStartDate}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="endDate">
              <label htmlFor="validationCustom01" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                name="endDate"
                placeholder="End Date"
                min={minEndDate}
                onChange={handleEndDate}
              />
            </div>
          </Col>
          <Col md={2}>
            <button
              type="button"
              onClick={filterHandler}
              className="excle-dowm"
            >
              {" "}
              Filter{" "}
            </button>
          </Col>
          <Col md={2}>
            {/* <DownloadTableExcel
              filename="Orders"
              sheet="Orders"
              currentTableRef={tableRef.current}
            >
              <button
                className="excle-dowm"
                disabled={!(currentItems?.length > 0)}
              >
                {" "}
                Export excel{" "}
              </button>
            </DownloadTableExcel> */}
            <button
              type="button"
              onClick={downloadExcelHandler}
              className="excle-dowm"
            >
              {" "}
              Export excel{" "}
            </button>
          </Col>
        </Row>
        <div className="dh-section mt-4 mx-4">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>Total Order</strong>
                    </span>
                    <h2>{orders?.totalOrder}</h2>
                  </div>
                </div>
                {/* <LineChart data={this.state.data1} options={options} /> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                {/* <span className="ms-chart-label bg-red">
                            <i className="material-icons">arrow_downward</i>{" "}
                            4.5%
                          </span> */}
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>Completed Order</strong>
                    </span>
                    <h2>{orders?.completedOrder}</h2>
                  </div>
                </div>
                {/* <LineChart data={this.state.data2} options={options} /> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>Pending Order</strong>
                    </span>
                    <h2>{orders?.pendingOrder}</h2>
                  </div>
                </div>
                {/* <LineChart data={this.state.data3} options={options} /> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                {/* <span className="ms-chart-label bg-red">
                            <i className="material-icons">arrow_upward</i> 9.5%
                          </span> */}
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>Cancel Order</strong>
                    </span>
                    <h2>{orders?.cancelOrder}</h2>
                  </div>
                </div>
                {/* <LineChart data={this.state.data4} options={options} /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="ms-panel-body">
          <div className="table-responsive">
            <table className="table table-hover thead-primary" ref={tableRef}>
              <thead>
                <tr>
                  <th scope="col">Sr.</th>

                  <th scope="col">Booking Number</th>
                  <th scope="col">User Name</th>
                  <th scope="col">User Phone Number</th>
                  <th scope="col">Members</th>
                  <th scope="col">Table Amount</th>
                  <th scope="col">Menu Amount</th>

                  <th scope="col">Booking Made On</th>
                  <th scope="col">Date & Time</th>
                  {/* <th scope="col">City</th> */}
                  <th scope="col">Booking For</th>
                  <th scope="col">Order Status</th>
                  <th scope="col">Is Verified</th>
                  {/* <th scope="col">Delivered Time</th> */}

                  <th scope="col">Accept/Decline/View/Verify</th>
                  {/* <th scope="col">Price</th> */}
                </tr>
              </thead>
              <tbody>
                {currentItems ? (
                  currentItems.map((order, index) => {
                    return (
                      <tr key={index}>
                        <td scope="row">{orders?.data?.indexOf(order) + 1}</td>

                        <td>{order?.bookingNumber}</td>
                        <td>{order.userName}</td>
                        <td>{order.userMobile}</td>
                        <td>{order.person}</td>
                        <td>{order?.tablePaidAmount}</td>
                        <td>{order?.menuPaidAmount}</td>
                        <td>{order?.bookingMadeOn}</td>

                        <td>{order.date}</td>
                        <td>{order.bookingType}</td>
                        {/* <td> {order.city}</td> */}
                        {/* <td>
                          <span
                            className={
                              order.status === "decline"
                                ? "badge badge-dark"
                                : order.status === "completed"
                                ? "badge badge-success"
                                : order.status === "accepted"
                                ? "badge badge-info"
                                : order.status === "cancel"
                                ? "badge badge-danger"
                                : "badge badge-primary"
                            }
                          >
                            {upperCase(
                              order.status == "cancel"
                                ? "Cancelled"
                                : order.status == "completed" &&
                                  order.isVerified == "no"
                                ? "Upcoming"
                                : order.status
                            )}
                          </span>
                        </td> */}
                        <td>
                          {/* <span
                            className={
                              order?.bookingLabel == "Pending"
                                ? "badge badge-primary"
                                : order?.bookingLabel == "Cancel"
                                ? "badge badge-danger"
                                : order?.bookingLabel == "Completed"
                                ? "badge badge-completed"
                                : order?.bookingLabel == "Upcoming"
                                ? "badge badge-info"
                                : order?.bookingLabel == "accepted"
                                ? "badge badge-success"
                                : ""
                            }
                          >
                            {upperCase(
                              order.bookingLabel == "Cancel"
                                ? "Cancelled"
                                : order.bookingLabel
                            )}
                          </span> */}

                          <span
                            className={
                              order?.bookingLabel == "Pending"
                                ? "badge badge-primary"
                                : order?.bookingLabel == "Cancel" ||
                                  order?.bookingLabel == "cancel"
                                ? "badge badge-danger"
                                : order?.bookingLabel == "completed" ||
                                  order?.bookingLabel == "Completed"
                                ? "badge badge-completed"
                                : order?.bookingLabel == "Upcoming"
                                ? "badge badge-info"
                                : order?.bookingLabel == "accepted"
                                ? "badge badge-success"
                                : order?.bookingLabel == "decline"
                                ? "badge badge-danger"
                                : ""
                            }
                          >
                            {upperCase(
                              order?.status == "Cancel" ||
                                order?.status == "cancel"
                                ? "Cancelled"
                                : order?.status
                            )}
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              order?.isVerified == "yes"
                                ? "badge badge-success"
                                : "badge badge-primary"
                            }
                          >
                            {upperCase(order?.isVerified)}
                          </span>
                        </td>
                        {/* <td>10:05</td> */}
                        {/* <td>$10</td> */}
                        <td>
                          {/* {order?.status == "pending" ? ( */}
                          <>
                            <button
                              disabled={
                                order?.status == "pending" ? false : true
                              }
                              type="button"
                              className="ms-btn-icon-outline btn-square btn-success"
                              onClick={() => {
                                Swal.fire({
                                  title: "Accept Order",
                                  showCancelButton: true,
                                  confirmButtonText: "Ok",
                                  showLoaderOnConfirm: true,
                                }).then(function (result) {
                                  if (result.isConfirmed) {
                                    dispatch(
                                      updateOrders({
                                        bookingId: order.bookingId,
                                        status: "accept",
                                      })
                                    )
                                      .unwrap()
                                      .then(() => {
                                        requestAllOrders(
                                          "POST",
                                          "order/orderHistory",
                                          {
                                            outletId: selectedOutletId
                                              ? selectedOutletId
                                              : localStorage.getItem(
                                                  "outletId"
                                                ),
                                          }
                                        );
                                        // dispatch(
                                        //   getOrders({
                                        //     outletId:
                                        //       localStorage.getItem("outletId"),
                                        //   })
                                        // );
                                        Swal.fire({
                                          icon: "success",
                                          html: "Order status updated successfully",
                                        });
                                      })
                                      .catch(() => {
                                        Swal.fire({
                                          icon: "error",
                                          html: "Something went wrong",
                                        });
                                      });
                                  }
                                });
                              }}
                            >
                              <i className="flaticon-tick-inside-circle mr-0"></i>
                            </button>
                            <button
                              type="button"
                              disabled={
                                order?.status == "pending" ? false : true
                              }
                              className="ms-btn-icon-outline btn-square btn-success ml-2"
                              onClick={() => {
                                Swal.fire({
                                  title: "Decline Order",
                                  showCancelButton: true,
                                  confirmButtonText: "Ok",
                                  showLoaderOnConfirm: true,
                                }).then(function (result) {
                                  if (result.isConfirmed) {
                                    dispatch(
                                      updateOrders({
                                        bookingId: order.bookingId,
                                        status: "decline",
                                      })
                                    )
                                      .unwrap()
                                      .then(() => {
                                        requestAllOrders(
                                          "POST",
                                          "order/orderHistory",
                                          {
                                            outletId: selectedOutletId
                                              ? selectedOutletId
                                              : localStorage.getItem(
                                                  "outletId"
                                                ),
                                          }
                                        );
                                        // dispatch(
                                        //   getOrders({
                                        //     outletId:
                                        //       localStorage.getItem("outletId"),
                                        //   })
                                        // );
                                        Swal.fire({
                                          icon: "success",
                                          html: "Order status updated successfully",
                                        });
                                      })
                                      .catch(() => {
                                        Swal.fire({
                                          icon: "error",
                                          html: "Something went wrong",
                                        });
                                      });
                                  }
                                });
                              }}
                            >
                              <i
                                className="flaticon-trash ms-delete-trigger mr-0"
                                style={{ color: "red" }}
                              ></i>
                            </button>
                          </>
                          {/* ):(order?.status == "accepted" || order?.status == "completed") ? ( */}
                          <button
                            disabled={
                              order?.status == "accepted" ||
                              order?.status == "completed"
                                ? false
                                : true
                            }
                            type="button"
                            className="ms-btn-icon-outline btn-square btn-info ml-2"
                            onClick={() => {
                              dispatch(
                                getOrderDetails({
                                  bookingId: order.bookingId,
                                })
                              )
                                .unwrap()
                                .then(() => {
                                  history.push(
                                    `/order-detail/${order.bookingId}/${
                                      hashKey ? hashKey : ""
                                    }`
                                  );
                                });
                            }}
                          >
                            <i className="flaticon-information mr-0"></i>
                          </button>
                          {/* ) :(order?.isVerified == "no" || order?.status == "completed") ? ( */}

                          <button
                            type="button"
                            disabled={
                              order?.isVerified == "no" &&
                              order?.status == "completed"
                                ? false
                                : true
                            }
                            className="ms-btn-icon-outline btn-square btn-info ml-2"
                            onClick={() => {
                              Swal.fire({
                                title: "Enter order code",
                                input: "text",
                                inputLabel: "code",
                                inputPlaceholder: "Enter code here",
                                inputAttributes: {
                                  maxlength: 8,
                                  autocapitalize: "off",
                                  autocorrect: "off",
                                  required: "true",
                                },
                                inputValidator: (value) => {
                                  if (value === "") {
                                    return "Please enter code!";
                                  }
                                },
                                confirmButtonText: "Submit",
                                confirmButtonColor: "#3ea99f",
                              })
                                .then((r) => {
                                  if (r.value === null) return false;

                                  if (r.isConfirmed) {
                                    dispatch(
                                      verifyOrder({
                                        bookingId: order.bookingId,
                                        secureCode: r.value,
                                      })
                                    )
                                      .unwrap()
                                      .then(() => {
                                        Swal.fire({
                                          icon: "success",
                                          title: "Order verified!",
                                          confirmButtonColor: "#3ea99f",
                                          confirmButtonText: "Ok",
                                        }).then((res) => {
                                          requestAllOrders(
                                            "POST",
                                            "order/orderHistory",
                                            {
                                              outletId: selectedOutletId
                                                ? selectedOutletId
                                                : localStorage.getItem(
                                                    "outletId"
                                                  ),
                                            }
                                          );
                                          // dispatch(
                                          //   getOrders({
                                          //     outletId:
                                          //       localStorage.getItem("outletId"),
                                          //   })
                                          // );
                                        });
                                      })
                                      .catch((err) => {
                                        toast.error("Invalid Code");
                                      });
                                  }
                                })
                                .catch((err) => {
                                  toast.error(err);
                                });
                            }}
                          >
                            <i className="flaticon-security mr-0"></i>
                          </button>
                          {/* ) : ('')} */}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7">No Orders Found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {currentItems && (
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ordertable;
