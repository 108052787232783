import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {Link} from "react-router-dom"
import { getMenuList } from "../../../redux/slices/Menu";
import {
  getAllOutlets,
  getOutletDetail,
  setCommision,
  setRewardPoint,
} from "../../../redux/slices/Outlet";
import { getCategories } from "../../../redux/slices/Registration";
import { getOrders } from "../../../redux/slices/Orders";
import useRequest from "../../../hooks/useRequest";
import {
  clearAllDetails,
  outletStepFourSave,
  outletStepOneSave,
  outletStepThreeSave,
  outletStepTwoSave,
} from "../../../redux/slices/SaveOutletDetails";
import { Button, Col, Modal, Row } from "react-bootstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

const OutletGrids = () => {
  const dispatch = useDispatch();

  const [showRewardModal, setShowRewardModal] = useState(false);
  const [showCommisionModal, setShowCommisionModal] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const history = useHistory();
  const handleShowRewardModal = () => setShowRewardModal(!showRewardModal);
  const handleShowCommisionModal = (commision) => {
    setSliderValue(commision);
    setShowCommisionModal(!showCommisionModal);
  }

  const [outletList, setOutletList] = useState([]);
  // const { vendorId } = useSelector((state) => state.register);
  const vendorId = localStorage.getItem('vendorId')
  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: vendorId })).unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.status == true) {
          setOutletList(res?.data)
        }
      }).catch(err => {

      });

  }, [])
  const renderRewardModal = () => {

    return (
      <Modal
        show={setShowRewardModal}
        onHide={handleShowRewardModal}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reward point partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          5% reward point will be offered as reward loyalty program
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(
                setRewardPoint({
                  status: "false",
                  outletId: localStorage.getItem("outletId"),
                })
              )
                .unwrap()
                .then(() => {
                  toast.success("Reward partner points activated successfully");
                  handleShowRewardModal();
                })
                .catch((err) => {
                  toast.error(err);
                  handleShowRewardModal();
                });
            }}
          >
            Deactivate
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(
                setRewardPoint({
                  status: "true",
                  outletId: localStorage.getItem("outletId"),
                })
              )
                .unwrap()
                .then(() => {
                  toast.success("Reward partner points activated successfully");
                  handleShowRewardModal();
                })
                .catch((err) => {
                  toast.error(err);
                  handleShowRewardModal();
                });
            }}
          >
            Activate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const renderCommisionModal = (outletId) => {
    return (
      <Modal
        show={setShowCommisionModal}
        onHide={handleShowCommisionModal}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Commision</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginBottom: "25px" }}>
            <Col md={6}>
              <label htmlFor="foodLicence" className="form-label customLabel">
                Commision
              </label>
              <Slider
                orientation="horizontal"
                min={5}
                max={100}
                value={sliderValue}
                onChange={(val) => setSliderValue(val)}
              />
              {sliderValue} %
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowCommisionModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(
                setCommision({
                  commission: sliderValue,
                  outletId: outletId,
                })
              )
                .unwrap()
                .then(() => {
                  toast.success("Commission set successfully");
                  handleShowCommisionModal();
                })
                .catch((err) => {
                  toast.error(err);
                  handleShowCommisionModal();
                });
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            {outletList?.length > 0 ? (
              outletList.map((item, i) => (
                <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div className="ms-card">
                    <div className="ms-card-imgssss">
                      {item && item.image && (
                        <img
                          // style={{
                          //   width: "100%",
                          //   height: "200px",
                          //   objectFit: "cover",
                          // }}
                          src={item.image}
                          alt="card_img"
                        />
                      )}

                    </div>
                    <div className="ms-card-body">
                      <div className="new">
                        <h6 className="mb-0">{item.outletName} </h6>
                        <h6 className="ms-text-primary mb-0">{i + 1}</h6>
                      </div>
                      <div classNamtotalCuisinee="new meta">
                        {/* <p>Qty:{item.qyt} </p> */}
                        {/* <span className={item.statuscls}>{item.status}</span> */}
                      </div>
                      <p>{item.description}</p>
                      <div className="new mb-0 mt-3">
                        <button
                          type="button"
                          className="btn grid-btn mt-0 btn-sm steps-btn"
                          onClick={() => {
                            history.push(`/view-outlet/${item.outletId}`);

                            // dispatch(getOutletDetail({ outletId: item.outletId }))
                            //   .unwrap()
                            //   .then((res) => {
                            //     history.push(`/view-outlet/${item.outletId}`);
                            //   })
                            //   .catch((err) => {
                            //     toast.error(err);
                            //   });
                          }}
                        >
                          View
                        </button>
                        <button
                          type="button"
                          className="btn grid-btn mt-0 btn-sm edit-btn btn-btn-set"
                          onClick={() => {
                            dispatch(clearAllDetails());
                            history.push(`/edit-outlet/${item.outletId}`);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                      <div className="new mt-4">
                        <button
                          type="button"
                          className={item?.menuActive  ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set" :"btn grid-btn mt-0 btn-sm steps-btn btn-btn-set red-color-btn"}
                          
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onClick={() => {

                            dispatch(getCategories("cuisine")).then((res) => {
                              console.log(res);
                              dispatch(
                                getMenuList({
                                  outletId: item.outletId,
                                  cusineId: res?.payload?.data[0]?._id,
                                })
                              )
                                .unwrap()
                                .then(() => {
                                  localStorage.setItem("outletId", item.outletId);
                                  history.push(`/menu-grid/${item.outletId}`);
                                });
                            });
                          }}
                        >
                          Menu
                        </button>

                        <button
                          type="button"
                          className="btn grid-btn mt-0 btn-sm steps-btn"
                          onClick={() => {
                            dispatch(
                              getOrders({
                                outletId: item.outletId,
                              })
                            )
                              .unwrap()
                              .then(() => {
                                localStorage.setItem("outletId", item.outletId);
                                history.push(`/orders/${item.outletId}`);
                              });
                          }}
                        >
                          Orders
                        </button>
                      </div>
                      <div className="new mt-4">
                        <button
                          type="button"
                          className="btn grid-btn mt-0 btn-sm steps-btn"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onClick={() => handleShowCommisionModal(item.commissionPoint)}
                        >
                          Commision
                        </button>

                        <Link
                        to={`/reward-page/${item.outletId}`}
                          className={item?.rewardActive  ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set" :"btn grid-btn mt-0 btn-sm steps-btn btn-btn-set red-color-btn"}
                          // onClick={handleShowRewardModal}
                        >
                          Reward point
                        </Link>
                        {showRewardModal && renderRewardModal()}
                        {showCommisionModal &&
                          renderCommisionModal(item.outletId)}
                      </div>
                      <div className="new mt-4">
                        <button
                          type="button"
                        
                          className={item?.discountActive  ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set" :"btn grid-btn mt-0 btn-sm steps-btn btn-btn-set red-color-btn"}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onClick={() => {
                            localStorage.setItem("outletId", item?.outletId);
                            history.push("/discount-grid");
                          }}
                        >
                          Discount
                        </button>

                        <button
                          type="button"
                          className="btn grid-btn mt-0 btn-sm steps-btn"
                          onClick={() => {
                            localStorage.setItem("outletId", item?.outletId);
                            history.push("/promotion-grid");
                          }}
                        >
                          Promotion
                        </button>
                        {showRewardModal && renderRewardModal()}
                        {showCommisionModal &&
                          renderCommisionModal(item.outletId)}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : ("No outlet Found.")}

          </div>
        </div>
      </div>
    </div>
  );
};

export default OutletGrids;

